import { motion } from 'framer-motion'
import {
  animateStateOpacityRotation,
  exitStateOpacityRotation,
  initialStateOpacityRotation,
  springMotionLowStiffness,
  whileHover,
  whileTap,
} from 'lib/framer-motion-constants'
import { twMerge } from 'tailwind-merge'

export default function DrawerButton({
  className,
  children,
  active,
  onClick,
}: {
  className?: string
  children?: React.ReactNode
  active?: boolean
  onClick?: () => void
}) {
  return (
    //  @ts-ignore
    <motion.button
      className={twMerge(
        `flex py-2 px-4 
      w-full
      relative
      text-lg lg:text-xl 
      justify-center items-center text-secondaryText-light 
      bg-drawerBackgroundPrimary-light rounded-full 
      border-drawerBackgroundSecondary-light border-2`,
        className
      )}
      whileHover={whileHover}
      whileTap={whileTap}
      transition={springMotionLowStiffness}
      onClick={onClick}
    >
      {children}
    </motion.button>
  )
}
