import { State } from '@hookstate/core'
import { websiteStateObject } from 'lib/website-state'

export default function DrawingBoard({
  websiteState,
}: {
  websiteState: State<typeof websiteStateObject>
}) {
  return (
    <div>
      This is the drawing area. You can drag your finger/mouse across the
      drawing board and create shapes.
      {websiteState.isTouchEnabled.get() ? (
        <>
          <br />
          Drag: draw
          <br />2 Fingers: Zoom, Pan
        </>
      ) : (
        <>
          <br />
          Left Click: Draw
          <br />
          Right Click: Pan
          <br />
          Wheel: Zoom
        </>
      )}
      <div className='mb-3' /> If your internet is working a design should be
      loaded for you now.
    </div>
  )
}
