import { State } from '@hookstate/core'
import { waterdrop_brushType, websiteStateObject } from 'lib/website-state'
import { FaMinus } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'
import SvgButtonRound from './svg-button-round'
//@ts-ignore
import { theme } from '@aeditto/configs/tailwind/tailwind.config'

export default function BrushTypeButton({
  className,
  active,
  websiteState,
}: {
  className?: string
  active?: boolean
  websiteState: State<typeof websiteStateObject, {}>
}) {
  const { brushType } = websiteState

  const toggleBrushType = () => {
    brushType.set((current) =>
      current === waterdrop_brushType.add
        ? waterdrop_brushType.sub
        : waterdrop_brushType.add
    )
  }

  const [accentColor, primaryText, backgroundSecondary] = [
    theme.extend.colors.accent.light,
    theme.extend.colors.primaryText.light,
    theme.extend.colors.secondaryBackground.light,
  ]

  const borderColor =
    brushType.get() === waterdrop_brushType.add
      ? 'border-primaryText-light'
      : 'border-accent-light'

  return (
    <div className={twMerge('relative flex', className)}>
      <SvgButtonRound
        id='brush_type_button'
        className={`p-[0.1rem] border-2 bg-transparent ` + borderColor}
        onClick={toggleBrushType}
        message={
          'Brush type changed to ' +
          (brushType.get() === waterdrop_brushType.add ? 'Eraser' : 'Brush')
        }
      >
        <div
          className='rounded-[50%] w-full h-full p-2'
          style={{
            backgroundColor:
              brushType.get() === waterdrop_brushType.add
                ? primaryText
                : accentColor,
          }}
        >
          <FaMinus className='w-full h-full text-secondaryText-light' />
        </div>
      </SvgButtonRound>
    </div>
  )
}
