import ArIcon from 'components/icons/ar'
import SvgButtonTransparent from './svg-button-transparent'
import ResetIcon from '../icons/reset'
import { useHookstate } from '@hookstate/core'
import { waterdropState } from 'lib/waterdrop-engine/waterdrop-state'
import { websiteState } from 'lib/website-state'
import { generateMatrix } from 'lib/waterdrop-engine/matrix-processor'

export default function ResetButton({
  className,
  active,
}: {
  className?: string
  active?: boolean
}) {
  const { baseGrid, baseGridCellSize, waterdropGridSize } =
    useHookstate(waterdropState)
  const { lastAddedPoint } = useHookstate(websiteState)

  const resetFunction = () => {
    baseGrid.set(
      generateMatrix(waterdropGridSize.get(), baseGridCellSize.get())
    )
    lastAddedPoint.set(undefined)
  }

  return (
    <SvgButtonTransparent
      id='reset_button'
      className={className}
      active={active}
      onClick={resetFunction}
    >
      <ResetIcon />
    </SvgButtonTransparent>
  )
}
