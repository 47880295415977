import InspirationsPreviousButton from './buttons/inspirations-previous-button'
import InspirationsNextButton from './buttons/inspirations-next-button'
import InspirationCanvas from './inspiration-canvas'
import { useHookstate } from '@hookstate/core'
import { websiteState } from 'lib/website-state'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { useWindowSize } from 'usehooks-ts'
import FooterSection from './footer-section'
import { listOfInspirationUUIDs } from 'lib/constants'

export default function InspirationSection() {
  const windowSize = useWindowSize()
  const minimumSizeOfCanvas = 455 //found from experimentation

  const { currentInspirationsPage } = useHookstate(websiteState)

  const [geometryStatePromisesAndIDs, setGeometryStatesPromises] = useState<
    {
      promise: Promise<Response>
      id: string
    }[]
  >([])

  const [showPreviousButton, setShowPreviousButton] = useState(false)
  const [showNextButton, setShowNextButton] = useState(false)

  useEffect(() => {
    let numberOfInspirationsPerPage = Math.floor(
      (windowSize.width - 96) / Math.max(0.18 * windowSize.width, 250)
    )

    numberOfInspirationsPerPage =
      numberOfInspirationsPerPage > 0 ? numberOfInspirationsPerPage : 1

    const startingInspirationIndex =
      currentInspirationsPage.get() * numberOfInspirationsPerPage

    startingInspirationIndex === 0
      ? setShowPreviousButton(false)
      : setShowPreviousButton(true)
    startingInspirationIndex + numberOfInspirationsPerPage >=
    listOfInspirationUUIDs.length
      ? setShowNextButton(false)
      : setShowNextButton(true)

    const listOfIDsToFetch = listOfInspirationUUIDs.slice(
      startingInspirationIndex,
      startingInspirationIndex + numberOfInspirationsPerPage
    )

    const promisesAndIDs = listOfIDsToFetch.map((id) => ({
      promise: fetch(window.location.origin + '/api/urls?id=' + id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      id: id,
    }))

    setGeometryStatesPromises(promisesAndIDs)
  }, [currentInspirationsPage, windowSize])

  return (
    <div className='h-screen w-full flex flex-col justify-between items-center pt-10 text-3xl lg:text-5xl '>
      <div className='flex flex-col items-center'>
        FIND INSPIRATION
        <div className='flex w-full justify-center items-center px-1'>
          {showPreviousButton && (
            <InspirationsPreviousButton
              inspirationsPageNumber={currentInspirationsPage}
            />
          )}
          {geometryStatePromisesAndIDs.map(
            (geometryStatePromiseAndID, index) => (
              <InspirationCanvas
                key={geometryStatePromiseAndID.id}
                geometryStatePromise={geometryStatePromiseAndID.promise}
                id={geometryStatePromiseAndID.id}
                minimumWidth={minimumSizeOfCanvas}
              />
            )
          )}
          {showNextButton && (
            <InspirationsNextButton
              inspirationsPageNumber={currentInspirationsPage}
            />
          )}
        </div>
      </div>
      <FooterSection />
    </div>
  )
}
