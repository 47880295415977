import RangeSlider from 'components/range-slider'
import SvgButtonRound from './svg-button-round'
import { twMerge } from 'tailwind-merge'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import {
  animateStateMenuPopper,
  exitStateMenuPopper,
  initialStateMenuPopper,
  springMotionMenuPopper,
} from 'lib/framer-motion-constants'
import { useElementSize } from 'usehooks-ts'
import { State, useHookstate } from '@hookstate/core'
import {
  waterdropState,
  waterdropStateObject,
} from 'lib/waterdrop-engine/waterdrop-state'
import { mod } from 'lib/aeditto-math'

export default function BrushSizeButton({
  className,
  waterdropState,
}: {
  className?: string
  waterdropState: State<typeof waterdropStateObject, {}>
}) {
  const { brushSizeRatio } = waterdropState

  const [sizeSliderVisible, setSizeSliderVisible] = useState(false)
  const [brushSizeButtonRef, { width, height }] = useElementSize()

  const MAX_BRUSH_SIZE_RATIO = 6
  const brushSizeValue = brushSizeRatio.get() / MAX_BRUSH_SIZE_RATIO

  const getNewBrushSize = (v: number) => mod(v, MAX_BRUSH_SIZE_RATIO) + 1

  const brushSizeToggle = () => {
    brushSizeRatio.set((v) => getNewBrushSize(v))
  }

  return (
    <div className={twMerge(`relative`, className)}>
      <AnimatePresence>
        {sizeSliderVisible && (
          //@ts-ignore
          <motion.div
            ref={brushSizeButtonRef}
            className={`absolute origin-bottom w-full h-[35vh]`}
            style={{ top: -1 * height }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={exitStateMenuPopper}
            transition={springMotionMenuPopper}
          >
            <RangeSlider
              min={1}
              max={MAX_BRUSH_SIZE_RATIO}
              step={1}
              value={brushSizeRatio.get()}
              setValue={brushSizeRatio.set}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <SvgButtonRound
        id='brush_size_button'
        className={`bg-primaryBackground-light 
          border-2 
          border-primaryText-light 
          flex
          items-center
          justify-center
          p-[0.1rem]`}
        onClick={() => brushSizeToggle()}
        message={
          'Brush size changed to: ' + getNewBrushSize(brushSizeRatio.get())
        }
      >
        <div
          className='bg-accent-light rounded-[50%]'
          style={{
            width: `calc(100% * ${brushSizeValue})`,
            height: `calc(100% * ${brushSizeValue})`,
          }}
        ></div>
      </SvgButtonRound>
    </div>
  )
}
