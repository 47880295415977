import BookmarkIcon from 'components/icons/bookmark_add'
import { AnimatePresence, motion } from 'framer-motion'
import SvgButtonTransparent from './svg-button-transparent'
import { State } from '@hookstate/core'
import { waterdropStateObject } from 'lib/waterdrop-engine/waterdrop-state'
import { saveToDatabase } from 'lib/serialisation-core'
import { ToastContainer, toast } from 'react-toastify'
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState,
} from 'react'
import { useElementSize } from 'usehooks-ts'
import { twMerge } from 'tailwind-merge'
import {
  animateStateMenuPopper,
  exitStateMenuPopper,
  initialStateMenuPopper,
  springMotionMenuPopper,
} from 'lib/framer-motion-constants'
import DrawerButton from './drawer-button'
import { NextRouter, useRouter } from 'next/router'

function DialogBox({
  urlValue,
  setDialogVisible,
}: {
  urlValue: string
  setDialogVisible: Dispatch<SetStateAction<boolean>>
}) {
  const copyRef = useRef<HTMLInputElement>(null)

  const copyFunction = () => {
    copyRef.current!.select()
    const copied = document.execCommand('copy')

    if (copied) {
      toast.info('URL was copied to your clipboard')
    } else {
      toast.warn('URL was not copied to your clipboard. Something went wrong')
    }

    setDialogVisible(false)
  }

  return (
    <div
      className={`w-full h-full 
    bg-drawerBackgroundPrimary-light z-20 
    rounded-xl shadow-md 
    flex flex-col items-center 
    px-4 py-10 mt-2`}
    >
      <input
        type='text'
        placeholder='Type your name'
        className='w-full h-10 rounded-md text-center placeholder-black placeholder-opacity-20 mb-6'
      />
      <input
        type='text'
        placeholder='Type your email address'
        className='w-full h-10 rounded-md text-center placeholder-black placeholder-opacity-20 mb-6'
      />
      <input
        ref={copyRef}
        value={urlValue}
        className='w-full h-10 rounded-md text-center placeholder-black placeholder-opacity-20 mb-6'
      ></input>
      <DrawerButton className='border-white' onClick={copyFunction}>
        {'Copy URL'}
      </DrawerButton>
    </div>
  )
}

async function getUrl(router: NextRouter, uuid: string) {
  await router.replace(
    {
      pathname: '/',
      query: {
        id: uuid,
      },
    },
    undefined,
    { shallow: true }
  )

  const url = window.location.href
  return url
}

export default function BookmarkButton({
  className,
  active,
  waterdropState,
}: {
  className?: string
  active?: boolean
  waterdropState: State<typeof waterdropStateObject, {}>
}) {
  const [dialogVisible, setDialogVisible] = useState(false)
  const [dialogRef, { width: dialogWidth, height: dialogHeight }] =
    useElementSize()

  const router = useRouter()
  const [urlValue, setUrlValue] = useState('')

  const saveFunction = async () => {
    if (!dialogVisible) {
      const savingPromise = saveToDatabase(waterdropState.get() as any)
      toast.promise(savingPromise, {
        pending: 'Saving design to database',
        success: 'Design Saved',
        error:
          "We couldn't save the design. Please check your internet connection",
      })
      const uuid = await savingPromise

      setUrlValue(await getUrl(router, uuid))
    }

    setDialogVisible((v) => !v)
  }

  return (
    <div className={twMerge('relative', className)}>
      <AnimatePresence>
        {dialogVisible && (
          //@ts-ignore
          <motion.div
            ref={dialogRef}
            className='absolute w-96 -left-[3.5rem] max-w-[65vw] z-20 origin-[3.5rem_0%]'
            style={{ bottom: -1 * dialogHeight }}
            initial={initialStateMenuPopper}
            animate={animateStateMenuPopper}
            exit={exitStateMenuPopper}
            transition={springMotionMenuPopper}
          >
            <DialogBox
              urlValue={urlValue}
              setDialogVisible={setDialogVisible}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <SvgButtonTransparent
        id='bookmark_button'
        active={active}
        onClick={saveFunction}
      >
        <BookmarkIcon />
      </SvgButtonTransparent>
    </div>
  )
}
