import { Shape, Vector3 } from 'three'
import * as THREE from 'three'
import * as AedittoMath from '../aeditto-math'

export function generateMatrix(
  gridSize: { x: number; y: number },
  cellSize: number
): number[][] {
  const noXCells = Math.floor(gridSize.x / cellSize)
  const noYCells = Math.floor(gridSize.y / cellSize)

  const returnArray: number[][] = []

  for (let j = 0; j < noYCells; j++) {
    returnArray.push([])

    for (let i = 0; i < noXCells; i++) {
      returnArray[j].push(0)
    }
  }

  return returnArray
}

/*
 ** This only calculates 1/4th of the indexes and the extrapolates
 ** Think, divide the circle in 4 parts.
 */
export function getListOfBrushAffectedIndexOffsets(
  cellSize: number,
  brushSize: number
) {
  const radius = brushSize / 2
  const maxAffectedIndexOffset = Math.floor(radius / cellSize)

  const affectedIndexOffsets = []

  let analyzedEqualPoint = -1

  for (let i = maxAffectedIndexOffset; i >= 0; i--) {
    const pointsToPush = []
    for (let j = 0; j <= maxAffectedIndexOffset; j++) {
      const currentPoint = [i * cellSize, j * cellSize]

      if (Math.hypot(...currentPoint) <= radius) {
        pointsToPush.push([i, j])
        if (i === j) {
          analyzedEqualPoint = i
          break
        }
      }
    }

    if (analyzedEqualPoint !== -1) break
    else affectedIndexOffsets.push(...pointsToPush)
  }

  const reversedIndexes = affectedIndexOffsets.map(([a, b]) => [b, a])
  affectedIndexOffsets.push(...reversedIndexes)

  for (let i = 0; i <= analyzedEqualPoint; i++) {
    for (let j = 0; j <= analyzedEqualPoint; j++) {
      affectedIndexOffsets.push([i, j])
    }
  }

  //this is only for a quarter circle and now calculating points on the whole circle
  const extrapolatedOffsets = affectedIndexOffsets.flatMap(([a, b]) => {
    const pointsReturnValues = []

    if (a !== 0 && b !== 0) {
      pointsReturnValues.push([-a, b], [a, -b], [-a, -b])
    } else if (a === 0 && b !== 0) {
      pointsReturnValues.push([0, -b])
    } else if (b === 0 && a !== 0) {
      pointsReturnValues.push([-a, 0])
    }

    return pointsReturnValues
  })

  affectedIndexOffsets.push(...extrapolatedOffsets)
  return affectedIndexOffsets
}

let cachedOffsets = new Map<string, number[][]>()

export function getListOfAffectedIndexes(
  absolutePoint: { x: number; y: number },
  gridSize: { x: number; y: number },
  cellSize: number,
  brushSize: number
) {
  const indexOfCenterCell = absolutePointToCellIndex(
    absolutePoint,
    gridSize,
    cellSize
  )

  if (indexOfCenterCell) {
    //Just makses sense to cache the offsets and allows for much better performance
    let listOfAffectedOffsetIndexes = []
    if (cachedOffsets.has(cellSize + '_' + brushSize)) {
      listOfAffectedOffsetIndexes = cachedOffsets.get(
        cellSize + '_' + brushSize
      )!
    } else {
      listOfAffectedOffsetIndexes = getListOfBrushAffectedIndexOffsets(
        cellSize,
        brushSize
      )
      cachedOffsets.clear()
      cachedOffsets.set(cellSize + '_' + brushSize, listOfAffectedOffsetIndexes)
    }

    const listOfAffectedIndexes = listOfAffectedOffsetIndexes.map((elem) => ({
      i: indexOfCenterCell.i + elem[0],
      j: indexOfCenterCell.j + elem[1],
    }))

    //TODO this returns way too many reduntants, reduce
    return listOfAffectedIndexes
  } else return []
}

export function absolutePointToCellIndex(
  absolutePoint: { x: number; y: number },
  gridSize: { x: number; y: number },
  cellSize: number
) {
  //in this case the point is outside the grid
  if (
    absolutePoint.x > Math.floor(gridSize.x / cellSize) * cellSize ||
    absolutePoint.y > Math.floor(gridSize.y / cellSize) * cellSize
  )
    return undefined

  const xIndex = Math.floor(absolutePoint.x / cellSize)
  const yIndex = Math.floor(absolutePoint.y / cellSize)

  return { i: xIndex, j: yIndex }
}

enum VertexType {
  topLeft,
  topRight,
  bottomRight,
  bottomLeft,
}

class NodeVertex {
  node: MatrixNode
  //0 for top left, 1 for top right, 2 for bottom right and 3 for bottom left
  vertexType: VertexType

  constructor(node: MatrixNode, vertexType: VertexType) {
    this.node = node
    this.vertexType = vertexType
  }

  isSameVertex(vertex: NodeVertex) {
    return (
      this.node.index.i === vertex.node.index.i &&
      this.node.index.j === vertex.node.index.j &&
      this.vertexType === vertex.vertexType
    )
  }

  getPosition() {
    switch (this.vertexType) {
      case 0:
        return new Vector3(
          this.node.position.x - this.node.cellSize / 2,
          this.node.position.y - this.node.cellSize / 2,
          0
        )
      case 1:
        return new Vector3(
          this.node.position.x + this.node.cellSize / 2,
          this.node.position.y - this.node.cellSize / 2,
          0
        )
      case 2:
        return new Vector3(
          this.node.position.x + this.node.cellSize / 2,
          this.node.position.y + this.node.cellSize / 2,
          0
        )
      case 3:
        return new Vector3(
          this.node.position.x - this.node.cellSize / 2,
          this.node.position.y + this.node.cellSize / 2,
          0
        )
      default:
        return new Vector3(0, 0, 0)
    }
  }
}

class Island {
  nodes: MatrixNode[]
  id: number

  constructor(nodes: MatrixNode[], id: number) {
    this.nodes = nodes
    this.nodes.forEach((el) => (el.island = this))
    this.id = id
  }

  addNode(node: MatrixNode) {
    node.island = this
    this.nodes.push(node)
  }

  addNodeToBeginning(node: MatrixNode) {
    node.island = this
    this.nodes.unshift(node)
  }

  mergeAndExhaustIsland(island: Island) {
    const nodesToAdd = island.nodes.splice(0)
    nodesToAdd.forEach((el) => this.addNode(el))
  }

  //orders two islands by the index of their top-left most node
  static orderIslands(island1: Island, island2: Island) {
    const indexTopLeft1 = island1.nodes[0].index
    const indexTopLeft2 = island2.nodes[0].index

    if (indexTopLeft1.j < indexTopLeft2.j) return [island1, island2]
    else if (
      indexTopLeft1.j === indexTopLeft2.j &&
      indexTopLeft1.i < indexTopLeft2.i
    )
      return [island1, island2]
    else return [island2, island1]
  }
}

export class MatrixNode {
  index: { i: number; j: number }
  value: number
  island?: Island
  position: Vector3
  cellSize: number

  constructor(
    index: { i: number; j: number },
    value: number,
    position: Vector3,
    cellSize: number,
    island?: Island
  ) {
    this.index = index
    this.value = value
    this.position = position
    this.cellSize = cellSize
    this.island = island
  }

  isSameIndex(node: MatrixNode) {
    return this.index.i === node.index.i && this.index.j === node.index.j
  }

  getVertex(type: VertexType) {
    return new NodeVertex(this, type)
  }
}

export function findIslands(nodeMatrix: MatrixNode[][]) {
  const islands: Island[] = []

  for (let jIndex = 0; jIndex < nodeMatrix.length; jIndex++) {
    for (let iIndex = 0; iIndex < nodeMatrix[0].length; iIndex++) {
      const curNode = nodeMatrix[jIndex][iIndex]
      curNode.island = undefined
      if (curNode.value !== 0) {
        const previousIIndex = curNode.index.i - 1
        const previousJIndex = curNode.index.j - 1
        const nextIIndex = curNode.index.i + 1
        const nextJIndex = curNode.index.j + 1

        if (
          previousIIndex >= 0 &&
          nodeMatrix[curNode.index.j][previousIIndex].value === 1
        ) {
          nodeMatrix[curNode.index.j][previousIIndex].island!.addNode(
            nodeMatrix[curNode.index.j][curNode.index.i]
          )
        } else if (
          previousJIndex >= 0 &&
          nodeMatrix[previousJIndex][curNode.index.i].value === 1
        ) {
          nodeMatrix[previousJIndex][curNode.index.i].island!.addNode(
            nodeMatrix[curNode.index.j][curNode.index.i]
          )
        } else if (
          previousIIndex >= 0 &&
          previousJIndex >= 0 &&
          nodeMatrix[previousJIndex][previousIIndex].value === 1
        ) {
          nodeMatrix[previousJIndex][previousIIndex].island!.addNode(
            nodeMatrix[curNode.index.j][curNode.index.i]
          )
        }

        if (previousJIndex >= 0 && nextIIndex < nodeMatrix[0].length) {
          if (nodeMatrix[previousJIndex][nextIIndex].value === 1) {
            if (curNode.island) {
              const orderedIslands = Island.orderIslands(
                curNode.island,
                nodeMatrix[previousJIndex][nextIIndex].island!
              )

              orderedIslands[0].mergeAndExhaustIsland(orderedIslands[1])
            } else {
              nodeMatrix[previousJIndex][nextIIndex].island!.addNode(curNode)
            }
          }
        }

        if (curNode.value === 1 && !curNode.island) {
          const newIsland = new Island([curNode], islands.length)
          islands.push(newIsland)
        }
      }
    }
  }
  return islands.filter((el) => el.nodes.length > 0)
}

enum Directions {
  right,
  down,
  left,
  up,
}
/*
 ** direction : start vertex type -> end vertex type
 ** 0 : 0 -> 1
 ** 1 : 1 -> 2
 ** 2 : 2 -> 3
 ** 3 : 3 -> 0
 */
const directions = {
  [Directions.right]: {
    previous: 0,
    next: 1,
    diagonalNode: { i: 1, j: -1 },
    adjacentNode: { i: 1, j: 0 },
  },
  [Directions.down]: {
    previous: 1,
    next: 2,
    diagonalNode: { i: 1, j: 1 },
    adjacentNode: { i: 0, j: 1 },
  },
  [Directions.left]: {
    previous: 2,
    next: 3,
    diagonalNode: { i: -1, j: 1 },
    adjacentNode: { i: -1, j: 0 },
  },
  [Directions.up]: {
    previous: 3,
    next: 0,
    diagonalNode: { i: -1, j: -1 },
    adjacentNode: { i: 0, j: -1 },
  },
}

function getDirection(previousVertex: NodeVertex, thisVertex: NodeVertex) {
  const foundObject = Object.entries(directions).find(
    (el) =>
      el[1].previous === previousVertex.vertexType &&
      el[1].next === thisVertex.vertexType
  )

  return Number(foundObject![0])
}

function getNextVertexAndDirection(
  matrix: MatrixNode[][],
  currentVertex: NodeVertex,
  direction: number
): [NodeVertex, number] {
  const diagonalNodeIndexOffset =
    directions[direction as keyof typeof directions].diagonalNode
  const diagonalNodeIndex = {
    i: currentVertex.node.index.i + diagonalNodeIndexOffset.i,
    j: currentVertex.node.index.j + diagonalNodeIndexOffset.j,
  }
  const diagonalNode = matrix[diagonalNodeIndex.j]
    ? matrix[diagonalNodeIndex.j][diagonalNodeIndex.i]
    : undefined

  const adjacentNodeIndexOffset =
    directions[direction as keyof typeof directions].adjacentNode
  const adjacentNodeIndex = {
    i: currentVertex.node.index.i + adjacentNodeIndexOffset.i,
    j: currentVertex.node.index.j + adjacentNodeIndexOffset.j,
  }
  const adjacentNode = matrix[adjacentNodeIndex.j]
    ? matrix[adjacentNodeIndex.j][adjacentNodeIndex.i]
    : undefined

  const cycleDirection = (n: number, cycleBy: number) =>
    AedittoMath.mod(n + cycleBy, 4)

  if (diagonalNode?.value === 1) {
    const nextVertex = diagonalNode.getVertex(direction)
    const nextDirection = getDirection(
      diagonalNode.getVertex(cycleDirection(direction, -1)),
      nextVertex
    )

    return [nextVertex, nextDirection]
  } else if (adjacentNode?.value === 1) {
    const nextVertex = adjacentNode.getVertex(cycleDirection(direction, 1))
    const nextDirection = getDirection(
      adjacentNode.getVertex(direction),
      nextVertex
    )

    return [nextVertex, nextDirection]
  } else {
    const nextVertex = currentVertex.node.getVertex(
      cycleDirection(direction, 2)
    )
    const nextDirection = getDirection(
      currentVertex.node.getVertex(cycleDirection(direction, 1)),
      nextVertex
    )

    return [nextVertex, nextDirection]
  }
}

export function getVerticesFromIsland(
  island: Island,
  matrix: MatrixNode[][]
): Vector3[] {
  const firstNode = island.nodes[0]
  const firstVertex = firstNode.getVertex(0)

  let currentVertex = firstNode.getVertex(1)
  let currentDirection = Directions.right
  let nextVertex: NodeVertex
  let nextDirection: number

  const vertices: Vector3[] = []
  vertices.push(firstVertex.getPosition())

  while (!currentVertex.isSameVertex(firstVertex)) {
    ;[nextVertex, nextDirection] = getNextVertexAndDirection(
      matrix,
      currentVertex,
      currentDirection
    )

    if (nextDirection !== currentDirection) {
      vertices.push(currentVertex.getPosition())
      currentDirection = nextDirection
    }

    currentVertex = nextVertex
  }

  vertices.push(firstVertex.getPosition())

  return vertices
}

export function getShapeFromVertices(
  vertices: Vector3[],
  cellSize: number,
  radiusRatio: number
) {
  const shape = new Shape()

  const absoluteRadius = (radiusRatio * cellSize) / 2

  shape.moveTo(vertices[0].x + absoluteRadius, vertices[0].y)

  vertices.slice(1).forEach((el, index, arr) => {
    const previousIndex = index === 0 ? arr.length - 1 : index - 1
    const nextIndex = index === arr.length - 1 ? 0 : index + 1
    const isOnSameXAxis = Math.abs(arr[previousIndex].y - el.y) < 0.00001

    if (isOnSameXAxis) {
      const multiplierX = el.x - arr[previousIndex].x > 0 ? -1 : 1

      const currentPoint = new THREE.Vector2(
        el.x + multiplierX * absoluteRadius,
        el.y
      )
      shape.lineTo(currentPoint.x, currentPoint.y)

      const multiplierNextY = arr[nextIndex].y - el.y < 0 ? -1 : 1
      const nextPoint = new THREE.Vector2(
        el.x,
        el.y + multiplierNextY * absoluteRadius
      )

      const controlPoint1 = new THREE.Vector2(
        currentPoint.x + -1 * multiplierX * absoluteRadius * 0.552,
        currentPoint.y
      )
      const controlPoint2 = new THREE.Vector2(
        nextPoint.x,
        nextPoint.y + -1 * multiplierNextY * absoluteRadius * 0.552
      )

      shape.bezierCurveTo(
        controlPoint1.x,
        controlPoint1.y,
        controlPoint2.x,
        controlPoint2.y,
        nextPoint.x,
        nextPoint.y
      )
    } else {
      const multiplierY = el.y - arr[previousIndex].y > 0 ? -1 : 1

      const currentPoint = new THREE.Vector2(
        el.x,
        el.y + multiplierY * absoluteRadius
      )
      shape.lineTo(currentPoint.x, currentPoint.y)

      const multiplierNextX = arr[nextIndex].x - el.x < 0 ? -1 : 1
      const nextPoint = new THREE.Vector2(
        el.x + multiplierNextX * absoluteRadius,
        el.y
      )

      const controlPoint1 = new THREE.Vector2(
        currentPoint.x,
        currentPoint.y + -1 * multiplierY * absoluteRadius * 0.552
      )
      const controlPoint2 = new THREE.Vector2(
        nextPoint.x + -1 * multiplierNextX * absoluteRadius * 0.552,
        nextPoint.y
      )

      shape.bezierCurveTo(
        controlPoint1.x,
        controlPoint1.y,
        controlPoint2.x,
        controlPoint2.y,
        nextPoint.x,
        nextPoint.y
      )
    }
  })

  return shape
}

/*
 ** The underlying grid is higher resolution and we use it to create the grid
 ** that has cell size = brush size
 */
//TODO write a test for this function
export function gridDownSampler(
  grid: number[][],
  baseCellSize: number,
  brushSize: number
) {
  const gridSize = {
    x: baseCellSize * grid[0].length,
    y: baseCellSize * grid.length,
  }

  const downSampledGrid = generateMatrix(gridSize, brushSize).map(
    (row, jIndex) =>
      row.map((cell, iIndex) => {
        const xPosition = iIndex * brushSize + brushSize / 2

        const yPosition = jIndex * brushSize + brushSize / 2

        return new MatrixNode(
          { i: iIndex, j: jIndex },
          cell,
          new Vector3(xPosition, yPosition, 0),
          brushSize
        )
      })
  )

  downSampledGrid.forEach((row, jIndex, matrixNodeGrid) =>
    row.forEach((matrixNode, iIndex) => {
      const indexesToCheck = getListOfAffectedIndexes(
        matrixNode.position,
        gridSize,
        baseCellSize,
        brushSize
      )

      const weight = indexesToCheck.reduce((accumulator, elem) => {
        if (
          elem.i > 0 &&
          elem.j > 0 &&
          elem.i < grid[0].length &&
          elem.j < grid.length
        )
          return accumulator + grid[elem.j][elem.i]
        else return 0
      }, 0)

      if (weight > indexesToCheck.length / 2) matrixNode.value = 1
    })
  )

  return downSampledGrid
}

export function isGridEmpty(grid: number[][]) {
  return !grid.reduce((acc, row) => {
    return row.reduce((rowAcc, cell) => rowAcc + cell, 0) + acc
  }, 0)
}
