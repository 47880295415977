export default function ThreeDViewContent() {
  return (
    <div>
      You are now in 3D view. You can drag across the screen to view your model.
      <div className='mb-3' />
      Be careful to not click on the dark area or you will have to restart the
      tour.
    </div>
  )
}
