import React from 'react'

export default function ArIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      version='1.1'
      viewBox='0 0 26 28'
    >
      <path
        fill='currentColor'
        stroke='none'
        strokeWidth='0.72'
        d='M21.643 7.295l.664-.312-.664-.313-1.443-.68.98-1.847 4.672 2.202v5.815h-2.19V8.653l-.538.253-1.945.917-.98-1.847zm.285 13.684l.592-.28-.561-.33-3.334-1.963 1.162-1.75 3.307 1.948.568.335v-3.317h2.19v5.817L21.18 23.64l-.98-1.847zM2.391 18.308v.63l.568-.334 3.306-1.947 1.163 1.75-3.334 1.962-.56.33.59.28 1.73.814-.98 1.847L.2 21.439v-5.817h2.191zm.537-9.402l-.536-.253V12.16H.2V6.345l4.673-2.202.979 1.847-1.443.68-.664.313.664.312 1.444.68-.98 1.848zm11.73-5.527l-.536-.252v3.506H11.93V3.126l-.537.253-1.944.917-.98-1.847L13.025.3l4.558 2.148-.98 1.847zm-3.264 21.025l.537.253V19.768h2.19V24.657l.538-.253 1.945-.917.98 1.847-4.558 2.147-4.556-2.147.979-1.847zm.537-11.04v-.216l-.205-.097-3.256-1.535.979-1.846 3.412 1.608.165.078.166-.078 3.412-1.608.98 1.846-3.257 1.535-.205.097v3.157H11.93z'
      ></path>
    </svg>
  )
}
