import React from 'react'

export default function BookmarkIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 26 29'
    >
      <path
        fill='currentColor'
        d='M.116 28.341V3.265c0-.831.279-1.525.836-2.082.556-.557 1.25-.835 2.082-.835h9.724v1.806H3.034c-.278 0-.533.115-.764.347-.232.232-.348.486-.348.764v22.277l9.03-3.883 9.03 3.883V12.99h1.807V28.34l-10.837-4.654L.116 28.341zM19.983 9.378V5.766H16.37V3.96h3.613V.348h1.806V3.96H25.4v1.806h-3.612v3.612h-1.806z'
      ></path>
    </svg>
  )
}
