import { useTour } from '@reactour/tour'
import { useEffect } from 'react'
import { getSteps } from './steps'
import { waterdropState } from 'lib/waterdrop-engine/waterdrop-state'
import { geometryState } from 'lib/geometry-engine/geometry-state'
import { websiteState } from 'lib/website-state'

export default function StateMachine() {
  const tour = useTour()
  const {
    setSteps,
    isOpen,
    setIsOpen,
    currentStep,
    setCurrentStep,
    meta,
    setMeta,
  } = tour

  useEffect(() => {
    setSteps!(getSteps(waterdropState, geometryState, websiteState, setIsOpen))
  }, [])

  return <div className='w-0 h-0 hidden'></div>
}
