import { GoChevronLeft } from 'react-icons/go'
import SvgButtonRound from './svg-button-round'
import { State } from '@hookstate/core'

export default function InspirationsPreviousButton({
  inspirationsPageNumber,
}: {
  inspirationsPageNumber: State<number, {}>
}) {
  const onClick = () => {
    inspirationsPageNumber.set((v) => v - 1)
  }

  return (
    <SvgButtonRound
      onClick={onClick}
      className='flex bg-transparent aspect-square rounded-[50%] border-0 shadow-custom_sm min-w-12 w-12 h-12 p-2 justify-center items-center'
    >
      <GoChevronLeft className='w-full h-full text-primaryText-light' />
    </SvgButtonRound>
  )
}
