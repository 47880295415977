import { State, useHookstate } from '@hookstate/core'
import { AnimatePresence, motion } from 'framer-motion'
import {
  springMotionLowStiffness,
  springMotionMenuPopper,
  whileHover,
  whileTap,
} from 'lib/framer-motion-constants'
import { CartElement, websiteState } from 'lib/website-state'
import { RxChevronRight, RxCross2, RxMinus, RxPlus } from 'react-icons/rx'
import { useState, useEffect } from 'react'
import SvgButtonRound from './buttons/svg-button-round'
import { twMerge } from 'tailwind-merge'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import DrawerButton from './buttons/drawer-button'

const stripePromise = () =>
  loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!)

function CartCardButton({
  className,
  children,
  onClick,
}: {
  className?: string
  children?: React.ReactNode
  onClick?: () => void
}) {
  return (
    //@ts-ignore
    <motion.div
      className={twMerge('w-8 h-8 text-secondaryText-light', className)}
      whileHover={whileHover}
      whileTap={whileTap}
      transition={springMotionLowStiffness}
      onClick={onClick}
    >
      {children}
    </motion.div>
  )
}

function CrossButton({
  uuid,
  cartElements,
}: {
  uuid: string
  cartElements: State<CartElement[], {}>
}) {
  const removeElement = () => {
    cartElements.set((curElements) =>
      curElements.filter((el) => el.uuid !== uuid)
    )
  }

  return (
    <CartCardButton onClick={removeElement}>
      <RxCross2 className='w-full h-full' />
    </CartCardButton>
  )
}

function PlusButton({
  uuid,
  cartElements,
}: {
  uuid: string
  cartElements: State<CartElement[], {}>
}) {
  const increaseQuantity = () => {
    cartElements.set((curElements) => {
      const newElements = [...curElements]
      const concernedElement = newElements.find((el) => el.uuid === uuid)
      concernedElement!.quantity += 1

      return newElements
    })
  }

  return (
    <CartCardButton onClick={increaseQuantity}>
      <RxPlus className='w-full h-full' />
    </CartCardButton>
  )
}

function MinusButton({
  uuid,
  cartElements,
}: {
  uuid: string
  cartElements: State<CartElement[], {}>
}) {
  const decreaseQuantity = () => {
    cartElements.set((curElements) => {
      const newElements = [...curElements]
      const concernedElement = newElements.find((el) => el.uuid === uuid)
      concernedElement!.quantity -= 1

      if (concernedElement!.quantity > 0) return newElements
      else return newElements.filter((el) => el.uuid !== uuid)
    })
  }

  return (
    <CartCardButton onClick={decreaseQuantity}>
      <RxMinus className='w-full h-full' />
    </CartCardButton>
  )
}

function CartCard({
  cartElement,
  cartElements,
}: {
  cartElement: CartElement
  cartElements: State<CartElement[], {}>
}) {
  return (
    <div className='relative w-full bg-drawerBackgroundSecondary-light rounded-xl p-5 lg:p-7 mb-2'>
      <div className='w-[5rem] h-[5rem] lg:w-[6rem] lg:h-[6rem] mb-2 rounded-xl overflow-hidden'>
        <img
          className='w-full h-full object-cover align-middle'
          src={cartElement.imageURL}
        ></img>
      </div>
      <div className='text-secondaryText-light text-2xl lg:text-3xl mb-3 lg:mb-4'>
        {cartElement.name}
      </div>
      <div className='text-tertiaryText-light text-lg lg:text-xl mb-1 lg:mb-2'>
        Price: €{cartElement.price.toFixed(2)}
      </div>
      <div className='text-tertiaryText-light text-lg lg:text-xl mb-1 lg:mb-2'>
        Quantity: {cartElement.quantity}
      </div>
      <div className='absolute top-0 right-0 h-full p-5 lg:p-7 flex flex-col justify-between'>
        <div>
          <CrossButton uuid={cartElement.uuid} cartElements={cartElements} />
        </div>
        <div>
          <div>
            <PlusButton uuid={cartElement.uuid} cartElements={cartElements} />
          </div>
          <div>
            <MinusButton uuid={cartElement.uuid} cartElements={cartElements} />
          </div>
        </div>
      </div>
    </div>
  )
}

function CartCardScroller({
  cartElements,
}: {
  cartElements: State<CartElement[], {}>
}) {
  return (
    <div className='w-full max-h-[75%] overflow-y-auto mb-2'>
      {cartElements.get().map((elem, index) => (
        <CartCard key={index} cartElement={elem} cartElements={cartElements} />
      ))}
    </div>
  )
}

function CheckoutButton({
  cartElements,
}: {
  cartElements: State<CartElement[], {}>
}) {
  const [isLoading, setIsLoading] = useState(false)
  const startCheckout = async () => {
    setIsLoading(true)
    try {
      const stripe = await stripePromise()
      const checkoutSession = await axios.post('/api/checkout_session', {
        cartElements: cartElements.get(),
      })

      const result = await stripe!.redirectToCheckout({
        sessionId: checkoutSession.data.id,
      })

      if (result.error) {
        alert(result.error.message)
      }

      setIsLoading(false)
    } catch (err) {
      alert(
        'We were not able to complete your request.\nWe apologize for the inconvenience'
      )
      console.log(err)
      setIsLoading(false)
    }
  }

  return (
    <DrawerButton
      onClick={() => {
        if (!isLoading) startCheckout()
      }}
    >
      {!isLoading ? 'Check Out' : 'Please Wait...'}
      <div className='absolute right-0 pr-2'>
        <RxChevronRight className='w-full h-full' />
      </div>
    </DrawerButton>
  )
}

export default function CartSidebar() {
  const { showCartSidebar, cartElements } = useHookstate(websiteState)

  return (
    <>
      <AnimatePresence>
        {showCartSidebar.get() && (
          <div className='absolute flex -right-0 top-0 h-screen max-w-[80%] w-[600px] z-20'>
            <SvgButtonRound
              className='bg-opacity-50 mt-20 mr-1 shadow-custom_sm'
              onClick={() => showCartSidebar.set(false)}
            >
              <RxCross2 className='w-full h-full' />
            </SvgButtonRound>

            {/*@ts-ignore*/}
            <motion.div
              className='relative h-full flex-1 bg-drawerBackgroundPrimary-light shadow-custom_sm p-2 lg:p-7'
              initial={{ opacity: 0, right: '-100%' }}
              animate={{ opacity: 1, right: 0 }}
              exit={{ opacity: 0 }}
              transition={springMotionMenuPopper}
            >
              <div className='text-secondaryText-light text-5xl mx-8 mt-8 mb-8'>
                Cart
              </div>
              <CartCardScroller cartElements={cartElements} />
              <div className='text-secondaryText-light text-lg lg:text-xl mb-2'>
                Total: €
                {cartElements
                  .get()
                  .reduce((acc, el) => acc + el.price * el.quantity, 0)
                  .toFixed(2)}
              </div>
              <CheckoutButton cartElements={cartElements} />
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  )
}
