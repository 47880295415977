import {
  CameraControls,
  ContactShadows,
  Environment,
  Html,
} from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import {
  mouseStateNoZoom,
  mouseStateRotate,
  touchStateNoZoom,
  touchStateRotate,
} from './camera-controls'
import { Box3, Vector3 } from 'three'
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { toast } from 'react-toastify'
import { waterdropStateObject } from 'lib/waterdrop-engine/waterdrop-state'
import {
  convertGridToShapes,
  convertShapeToUDefinition,
  functionCreateObjectDefinition,
  getLongestShape,
  getVDefinitionFromLength,
} from 'lib/waterdrop-engine/geomery-processor'
import SpinnerIcon from './icons/spinner'
import { useElementSize } from 'usehooks-ts'
import { useRouter } from 'next/router'

function CameraUpdater({
  cameraControlsRef,
  box,
  canvasWidth,
}: {
  cameraControlsRef: MutableRefObject<CameraControls | null>
  box: Box3
  canvasWidth: number
}) {
  if (!cameraControlsRef.current || !box) return
  const midPointOfBox = box.max.clone().add(box.min).divideScalar(2)
  const biggerSide =
    box.max.y - box.min.y > box.max.x - box.min.x
      ? box.max.y - box.min.y
      : box.max.x - box.min.x

  cameraControlsRef.current!.moveTo(
    0,
    midPointOfBox.y + 40,
    midPointOfBox.z + box.max.distanceTo(midPointOfBox) + 20,
    true
  )

  cameraControlsRef.current!.setTarget(
    midPointOfBox.x,
    midPointOfBox.y,
    midPointOfBox.z,
    true
  )

  //440 because the zoom is ideal for an object of size 440 on one side
  //455 because zoom is ideal for canvas of size 455
  cameraControlsRef.current!.zoomTo(
    (440 * 0.7 * canvasWidth) / 455 / biggerSide,
    true
  )
}

function MeshObjects({
  waterdropState,
  cameraControlsRef,
  canvasWidth,
}: {
  cameraControlsRef: MutableRefObject<CameraControls | null>
  waterdropState: typeof waterdropStateObject | undefined
  canvasWidth: number
}) {
  if (!waterdropState) return null

  const {
    baseGrid,
    radiusRatio,
    baseGridCellSize,
    modelColor,
    waterdropGridSize,
    brushSizeRatio,
    height,
    minBrushSize,
  } = waterdropState
  const brushSize = brushSizeRatio * minBrushSize

  const shapes = convertGridToShapes(
    //@ts-ignore
    baseGrid,
    baseGridCellSize,
    brushSize,
    radiusRatio
  )

  const longestShape = getLongestShape(shapes)

  if (longestShape) {
    const uObject = convertShapeToUDefinition(longestShape)
    const vObject = getVDefinitionFromLength(height)
    const completeObject = functionCreateObjectDefinition(
      uObject,
      vObject,
      modelColor,
      baseGridCellSize,
      waterdropGridSize,
      height
    )

    const objectMesh = completeObject.getMesh(0.001, 1)

    const box = new Box3()
    box.setFromObject(objectMesh)

    CameraUpdater({ cameraControlsRef, box, canvasWidth })

    return (
      <>
        <mesh
          geometry={objectMesh.geometry}
          material={objectMesh.material}
          position={new Vector3(0, 0, 0)}
        />
        <ContactShadows
          //6 because the scale was perfect for an object of size 6
          scale={(box.min.distanceTo(box.max) * 100) / 6}
          position={new Vector3(0, box.min.y - 10, 0)}
          visible={true}
          far={box.min.distanceTo(box.max)}
        />
      </>
    )
  } else {
    return null
  }
}

export default function InspirationCanvas({
  geometryStatePromise,
  minimumWidth,
  id,
}: {
  geometryStatePromise: Promise<Response>
  minimumWidth: number
  id: string
}) {
  const [geometryState, setGeometryState] = useState<
    typeof waterdropStateObject | undefined
  >(undefined)

  useEffect(() => {
    if (geometryState) return

    const setStateFromPromise = async () => {
      try {
        const res = await geometryStatePromise
        const allPosts = await res.json()

        if (allPosts.data.length !== 0) {
          setGeometryState(allPosts.data[0])
        } else {
          toast.error(
            'Could not load your design from the database. Please check your internet connection.'
          )
        }
      } catch (e) {
        console.log('promise not loaded')
      }
    }

    setStateFromPromise()
  }, [])

  const [canvasDivRef, { width: canvasWidth, height: canvasHeight }] =
    useElementSize()

  const cameraControlsRef = useRef<CameraControls | null>(null)

  const doNotClick = useRef(false)
  const openDesign = () => {
    if (!doNotClick.current)
      window.open(window.location.origin + '?id=' + id, '_blank')?.focus()
    else doNotClick.current = false
  }

  return (
    <div
      className='mx-2  aspect-square rounded-2xl cursor-pointer'
      style={{ width: '18vw', minWidth: 250 + 'px' }}
      ref={canvasDivRef}
      onClick={openDesign}
      onMouseMove={(e: any) => {
        if (e.buttons !== 0) doNotClick.current = true
      }}
    >
      <Canvas
        gl={{ preserveDrawingBuffer: true }}
        shadows
        orthographic
        color='black'
        style={{ zIndex: 0 }}
        onCreated={({ camera }) => {
          const newCamera = camera as THREE.OrthographicCamera
          newCamera.left = 200
          newCamera.right = -200
          newCamera.top = 200
          newCamera.bottom = -200
          newCamera.near = 1
          newCamera.far = 4000
        }}
      >
        {geometryState && canvasWidth && cameraControlsRef.current && (
          <MeshObjects
            cameraControlsRef={cameraControlsRef}
            waterdropState={geometryState}
            canvasWidth={canvasWidth}
          />
        )}
        {!geometryState && (
          <Html
            transform
            occlude={'blending'}
            prepend
            style={{
              width: '200px',
              height: '200px',
            }}
            distanceFactor={400}
            name='drawingBoard'
          >
            <div className='flex items-center justify-center'>
              <SpinnerIcon />
            </div>
          </Html>
        )}
        <Environment
          near={1}
          far={4000}
          resolution={256}
          files='/static/img/empty_warehouse_01_1k.hdr'
        />
        {/*box && (
          <ContactShadows
            scale={(box.min.distanceTo(box.max) * 100) / 6}
            position={new Vector3(0, (box.min.distanceTo(box.max) * -3) / 6, 0)}
            visible={true}
            far={box.min.distanceTo(box.max)}
          />
        )*/}
        <CameraControls
          makeDefault
          ref={cameraControlsRef}
          mouseButtons={mouseStateNoZoom}
          touches={touchStateNoZoom}
          truckSpeed={30}
        />
      </Canvas>
    </div>
  )
}
