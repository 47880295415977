import { useHookstate } from '@hookstate/core'
import { Direction, Range, getTrackBackground } from 'react-range'
//@ts-ignore
import { theme } from '@aeditto/configs/tailwind/tailwind.config'
import { websiteState } from 'lib/website-state'

export default function RangeSlider({
  direction = Direction.Up,
  trackWidth = '0.25rem',
  thumbWidth = '2.5rem',
  min,
  max,
  step,
  value,
  setValue,
}: {
  min: number
  max: number
  step: number
  value: number
  setValue: (n: number) => void
  direction?: Direction
  trackWidth?: string
  thumbWidth?: string
}) {
  const onValueChange = (values: number[]) => setValue(values[0])

  const [accentColor, backgroundPrimary, backgroundSecondary] = [
    theme.extend.colors.accent.light,
    theme.extend.colors.primaryBackground.light,
    theme.extend.colors.secondaryBackground.light,
  ]

  const isVertical =
    direction === Direction.Up || direction === Direction.Down ? true : false

  const { sliderActive } = useHookstate(websiteState)

  return (
    <div className='h-full w-full justify-center'>
      <Range
        values={[value]}
        step={step}
        min={min}
        max={max}
        onChange={onValueChange}
        direction={direction}
        renderTrack={({ props, children }) => {
          return (
            <div
              onMouseDown={(e: React.MouseEvent) => {
                sliderActive.set(true)
                props.onMouseDown(e)
              }}
              onMouseUp={() => sliderActive.set(false)}
              onTouchEnd={() => sliderActive.set(false)}
              onTouchStart={(e: React.TouchEvent) => {
                sliderActive.set(true)
                props.onTouchStart(e)
              }}
              className='h-full w-full'
              style={{
                ...props.style,
                paddingTop: isVertical ? '0px' : `calc(50% - ${trackWidth}/2)`,
                paddingLeft: isVertical ? `calc(50% - ${trackWidth}/2)` : '0px',
                paddingBottom: `calc(${thumbWidth}/2 + 0.5em)`,
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: isVertical ? '100%' : trackWidth,
                  width: isVertical ? trackWidth : '100%',
                  borderRadius: trackWidth,
                  background: getTrackBackground({
                    values: [value],
                    colors: [accentColor, backgroundSecondary],
                    min: min,
                    max: max,
                    direction: direction,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )
        }}
        renderThumb={({ props, isDragged }) => {
          return (
            <div
              {...props}
              className='rounded-[50%] border-primaryText-light'
              style={{
                ...props.style,
                height: thumbWidth,
                width: thumbWidth,
                borderWidth: '0.75rem',
                backgroundColor: isDragged ? accentColor : backgroundPrimary,
                boxShadow: '0px 2px 6px #AAA',
              }}
            ></div>
          )
        }}
      />
    </div>
  )
}
