import { twMerge } from 'tailwind-merge'
import SvgButtonRound from './buttons/svg-button-round'
import ThreeDButton from './buttons/three-d-button'
import ColorButton from './buttons/color-button'
import BottomButton from './buttons/step-button'
import AddToCartButton from './buttons/add-to-cart-button'
import { useHookstate } from '@hookstate/core'
import { websiteState } from 'lib/website-state'
import { geometryState } from 'lib/geometry-engine/geometry-state'
import { useEffect, useState } from 'react'
import BottomPriceDisplay from './bottom-price-display'

export default function StepPicker({
  className,
  children,
}: {
  className?: string
  children?: React.ReactNode
}) {
  const { objects } = useHookstate(geometryState)
  const { threeDActive } = useHookstate(websiteState)

  const [price, setPrice] = useState(0)

  useEffect(() => {
    const prc = objects
      .get()
      .reduce((acc, obj) => acc + obj.getPrice(0.001, 1), 0)
    setPrice(prc)
  }, [objects])

  return (
    <div className={twMerge('w-[100%] flex justify-center', className)}>
      <div className='flex relative'>
        <AddToCartButton />
        {threeDActive.get() && <BottomPriceDisplay price={price} />}
      </div>
    </div>
  )
}
