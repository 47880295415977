import { State } from '@hookstate/core'
import { useTour } from '@reactour/tour'
import { websiteStateObject } from 'lib/website-state'
import ThreeDIcon from '../icons/three-d-icon'
import SvgButtonRound from './svg-button-round'

export default function ThreeDButton({
  className,
  active,
  websiteState,
}: {
  className?: string
  active?: boolean
  websiteState: State<typeof websiteStateObject, {}>
}) {
  const { threeDActive } = websiteState!
  const { isOpen, setCurrentStep } = useTour()

  const onClick = () => {
    threeDActive.set((v) => !v)
    if (isOpen) setTimeout(() => setCurrentStep((v) => v + 1), 1000)
  }

  return (
    <SvgButtonRound
      id={'three_d_button'}
      className={className}
      active={threeDActive.get()}
      onClick={onClick}
      message={
        threeDActive.get() ? 'Drawing mode activated' : '3D mode activated'
      }
    >
      <ThreeDIcon />
    </SvgButtonRound>
  )
}
