import { hookstate } from '@hookstate/core'
import {
  listOfPickerColors,
  waterdrop_initial_baseGridCellSize,
  waterdrop_initial_gridSize,
  waterdrop_max_brushSize,
  waterdrop_min_brushSize,
} from '../constants'
import { generateMatrix } from './matrix-processor'

export const waterdropStateObject = {
  type: 'waterdrop',
  waterdropGridSize: waterdrop_initial_gridSize,
  baseGridCellSize: waterdrop_initial_baseGridCellSize,
  baseGrid: generateMatrix(
    waterdrop_initial_gridSize,
    waterdrop_initial_baseGridCellSize
  ),
  minBrushSize: waterdrop_min_brushSize,
  brushSizeRatio: 2,
  radiusRatio: 1,
  height: 100,
  modelColor: listOfPickerColors[0],
}

export const waterdropState = hookstate(waterdropStateObject)
