import { twMerge } from 'tailwind-merge'
import SvgButtonRound from './buttons/svg-button-round'
import ThreeDButton from './buttons/three-d-button'
import ColorButton from './buttons/color-button'
import StepPicker from './step-picker'
import BrushSizeButton from './buttons/brush-size-button'
import { AnimatePresence } from 'framer-motion'
import BrushTypeButton from './buttons/brush-type-button'
import ResetButton from './buttons/doover-button'
import { useHookstate } from '@hookstate/core'
import { websiteState } from 'lib/website-state'
import { waterdropState } from 'lib/waterdrop-engine/waterdrop-state'
import HeightButton from './buttons/height-button'
import MeasurementsButton from './buttons/measurements-button'
import MagicButton from './buttons/magic-button'
import { isTouchDevice } from 'lib/commons'
import { useEffect, useState } from 'react'

function whichBottomPanelToShow(
  threeDActive: boolean,
  bottomPanelIndex: number
) {
  if (!threeDActive) return bottomPanelIndex
  else return -1
}

export default function BottomDrawer({
  className,
  children,
}: {
  className?: string
  children?: React.ReactNode
}) {
  const webState = useHookstate(websiteState)
  const waterState = useHookstate(waterdropState)

  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])

  return (
    <div
      id='bottom_drawer'
      className={twMerge(
        'relative w-[100%] flex flex-col items-center justify-center mb-4',
        className
      )}
    >
      {whichBottomPanelToShow(
        webState.threeDActive.get(),
        webState.bottomPanelIndex.get()
      ) === 0 && (
        <div className='flex mb-3 z-10'>
          <ThreeDButton websiteState={webState} className='mr-3' />
          <BrushTypeButton websiteState={webState} className='mr-3' />
          <BrushSizeButton waterdropState={waterState} className='mr-3' />
          <MagicButton websiteState={webState} />
        </div>
      )}

      {whichBottomPanelToShow(
        webState.threeDActive.get(),
        webState.bottomPanelIndex.get()
      ) === -1 && (
        <div className='flex mb-3 z-10'>
          <ThreeDButton websiteState={webState} className='mr-3' />
          <ColorButton waterdropState={waterState} className='mr-3' />
          <MeasurementsButton websiteState={webState} className='mr-3' />
          <HeightButton waterdropState={waterState} className='mr-3' />
        </div>
      )}
      <StepPicker className='mb-2' />
      <div className='text-secondaryBackground-light font-light text-sm'>
        {hasMounted && isTouchDevice()
          ? 'Swipe up for more'
          : 'Scroll here for more'}
      </div>
    </div>
  )
}
