import AedittoLogo from './icons/aeditto-logo'

function ContactAndShipping() {
  return (
    <div className='flex-grow-[1.5] h-full flex flex-col items-center justify-center lg:border-r-[3px] border-black'>
      <a href='/about_us'>ABOUT US</a>
    </div>
  )
}

function TermsAndConditions() {
  return (
    <div className='flex-grow-[1] h-full flex flex-col items-center justify-center lg:border-r-[3px] border-black'>
      <a href='/privacy_policy'>PRIVACY POLICY</a>
    </div>
  )
}

function SocialMedia() {
  return (
    <div className='flex-grow-[1.5] h-full flex flex-col items-center justify-center'>
      <a href={'https://www.instagram.com/aeditto_com/'} className='mb-10'>
        INSTAGRAM
      </a>
      <a href={'https://www.instagram.com/aeditto_com/'}>FACEBOOK</a>
    </div>
  )
}
export default function FooterSection() {
  return (
    <div className='w-full h-60 flex flex-col justify-center items-center mb-4 text-base'>
      <div className='mb-1'>DESIGNED BY YOU, PRODUCED BY US</div>
      <a className='mt-0' href='https://aeditto.com/'>
        <AedittoLogo />
      </a>
    </div>
  )
}
