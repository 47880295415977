import { State } from '@hookstate/core'
import { websiteStateObject } from 'lib/website-state'
import MeasureIcon from '../icons/measurement-icon'
import SvgButtonRound from './svg-button-round'

export default function MeasurementsButton({
  className,
  active,
  websiteState,
}: {
  className?: string
  active?: boolean
  websiteState: State<typeof websiteStateObject, {}>
}) {
  const { showMeasurements } = websiteState!

  const onClick = () => {
    showMeasurements.set((v) => !v)
  }

  return (
    <SvgButtonRound
      id='measure_button'
      className={className}
      active={showMeasurements.get()}
      onClick={onClick}
    >
      <MeasureIcon />
    </SvgButtonRound>
  )
}
