import { State } from '@hookstate/core'
import { useTour } from '@reactour/tour'
import { websiteStateObject } from 'lib/website-state'
import ThreeDIcon from '../icons/three-d-icon'
import SvgButtonRound from './svg-button-round'
import { toast } from 'react-toastify'
import { FaWandMagicSparkles } from 'react-icons/fa6'
import { AnimatePresence } from 'framer-motion'
import { listOfInspirationUUIDs } from 'lib/constants'
import RotateQuestion from '../icons/rotate-question'

export default function MagicButton({
  className,
  active,
  websiteState,
}: {
  className?: string
  active?: boolean
  websiteState: State<typeof websiteStateObject, {}>
}) {
  const onClick = () => {
    const id =
      listOfInspirationUUIDs[
        Math.floor(Math.random() * listOfInspirationUUIDs.length)
      ]
    window.open(window.location.origin + '?id=' + id, '_blank')?.focus()
  }

  return (
    <AnimatePresence>
      {!websiteState.threeDActive.get() && (
        <SvgButtonRound
          id={'magic_button'}
          className={className}
          onClick={onClick}
        >
          <RotateQuestion />
        </SvgButtonRound>
      )}
    </AnimatePresence>
  )
}
