import RangeSlider from 'components/range-slider'
import SvgButtonRound from './svg-button-round'
import { twMerge } from 'tailwind-merge'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import {
  animateStateMenuPopper,
  exitStateMenuPopper,
  initialStateMenuPopper,
  springMotionMenuPopper,
} from 'lib/framer-motion-constants'
import { useElementSize } from 'usehooks-ts'
import { State, useHookstate } from '@hookstate/core'
import {
  waterdropState,
  waterdropStateObject,
} from 'lib/waterdrop-engine/waterdrop-state'
import { modelTypes } from 'lib/geometry-engine/mesh-maker'

export default function HeightButton({
  className,
  waterdropState,
}: {
  className?: string
  waterdropState: State<typeof waterdropStateObject, {}>
}) {
  const { height: modelHeight, waterdropGridSize } = waterdropState

  const [sizeSliderVisible, setSizeSliderVisible] = useState(false)
  const [brushSizeButtonRef, { width, height }] = useElementSize()

  return (
    <div className={twMerge(`relative`, className)}>
      <AnimatePresence>
        {sizeSliderVisible && (
          //@ts-ignore
          <motion.div
            ref={brushSizeButtonRef}
            className={`absolute origin-bottom w-full h-[35vh]`}
            style={{ top: -1 * height }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={exitStateMenuPopper}
            transition={springMotionMenuPopper}
          >
            <RangeSlider
              min={modelTypes.waterdrop.minHeight}
              max={waterdropGridSize.get().z}
              step={50}
              value={modelHeight.get()}
              setValue={modelHeight.set}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <SvgButtonRound
        id='height_button'
        className={`bg-primaryBackground-light 
          border-2 
          border-primaryText-light 
          flex
          items-center
          justify-center
          p-[0.1rem]`}
        onClick={() => setSizeSliderVisible((v) => !v)}
      >
        <div className='bg-transparent text-primaryText-light rounded-[50%]'>
          {modelHeight.get()}
        </div>
      </SvgButtonRound>
    </div>
  )
}
