import { Dispatch, SetStateAction } from 'react'

export default function HelpButtonContent({
  setIsOpen,
}: {
  setIsOpen: Dispatch<SetStateAction<Boolean>>
}) {
  return (
    <div>
      You can click here any time to retake this tour.
      <div className='mt-3'>
        <button
          onClick={() => setIsOpen(false)}
          className='bg-drawerBackgroundPrimary-light text-secondaryText-light p-2 rounded-md shadow-md'
        >
          Let's Go
        </button>
      </div>
    </div>
  )
}
