import React from 'react'

export default function MeasureIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 27 28'
    >
      <path
        stroke='#fff'
        strokeWidth='1.048'
        d='M19.846 0.907H28.929000000000002V27.806H19.846z'
        transform='rotate(45 19.846 .907)'
      ></path>
      <path
        stroke='#fff'
        strokeWidth='1.048'
        d='M16.549 3.462L19.761 6.674'
      ></path>
      <path
        stroke='#fff'
        strokeWidth='1.048'
        d='M13.493 6.519L16.704 9.73'
      ></path>
      <path
        stroke='#fff'
        strokeWidth='1.048'
        d='M10.437 9.575L13.648 12.786'
      ></path>
      <path
        stroke='#fff'
        strokeWidth='1.048'
        d='M7.381 12.63L10.593 15.842'
      ></path>
      <path
        stroke='#fff'
        strokeWidth='1.048'
        d='M4.326 15.686L7.537 18.897'
      ></path>
    </svg>
  )
}
