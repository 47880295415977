import BookmarkIcon from 'components/icons/bookmark_add'
import SvgButtonTransparent from './svg-button-transparent'
import { State } from '@hookstate/core'
import { waterdropStateObject } from 'lib/waterdrop-engine/waterdrop-state'
import { saveToDatabase } from 'lib/serialisation-core'
import { websiteStateObject } from 'lib/website-state'
import { PiShoppingCart } from 'react-icons/pi'

export default function CartButton({
  className,
  active,
  websiteState,
}: {
  className?: string
  active?: boolean
  websiteState: State<typeof websiteStateObject, {}>
}) {
  const toggleCart = () => {
    websiteState.showCartSidebar.set((v) => !v)
  }

  return (
    <SvgButtonTransparent
      className={className}
      active={active}
      onClick={toggleCart}
    >
      <PiShoppingCart className='w-full h-full' />
    </SvgButtonTransparent>
  )
}
