export const springMotion = {
  type: 'spring',
  stiffness: 200,
  damping: 10,
}

export const springMotionLowStiffness = {
  type: 'spring',
  stiffness: 100,
  damping: 10,
}
export const springMotionMenuPopper = { duration: 0.4, type: 'spring' }

export const whileTap = { scale: 0.9 }
export const whileHover = { scale: 1.1 }

export const initialStateOpacityRotation = { opacity: 0, rotate: -180 }
export const animateStateOpacityRotation = { opacity: 1, rotate: 0 }
export const exitStateOpacityRotation = { opacity: 0, rotate: 180 }

export const initialStateOpacityScale = { opacity: 0, scale: 0.8 }
export const animateStateOpacityScale = { opacity: 1, scale: 1 }
export const exitStateOpacityScale = { opacity: 0, scale: 0.8 }

export const initialStateMenuPopper = { opacity: 0, scaleY: 0.1 }
export const animateStateMenuPopper = { opacity: 1, scaleY: 1 }
export const exitStateMenuPopper = { opacity: 0, scale: 0.3 }
