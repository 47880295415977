export default function ThreeDButtonContent() {
  return (
    <div>
      This is the 3D Button. it will take you from drawing mode to 3D view and
      you will see your object.
      <div className='mb-3' />
      Please click the button
    </div>
  )
}
