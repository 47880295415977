export default function BottomPriceDisplay({ price }: { price: number }) {
  return (
    <div className={`relative h-0`}>
      <div
        id='price_display'
        className={`absolute pl-3 text-tertiaryTextDarker-light`}
      >
        {'€' + price}
      </div>{' '}
    </div>
  )
}
