import React from 'react'

export default function ThreeDIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 28 28'
    >
      <path
        fill='currentColor'
        d='M8.681 24.711a12.053 12.053 0 01-6.861-9.746H.096C.682 22.045 6.6 27.607 13.83 27.607l.759-.034-4.38-4.38-1.528 1.518zm1.023-7.494c-.218 0-.425-.034-.598-.092a1.228 1.228 0 01-.46-.275 1.14 1.14 0 01-.298-.426 1.372 1.372 0 01-.104-.54H6.75c0 .414.08.782.242 1.092.16.31.379.575.643.793.276.207.586.368.943.471.345.115.712.173 1.103.173.425 0 .828-.058 1.184-.173.368-.115.69-.287.954-.505.264-.219.483-.495.632-.828.15-.333.23-.701.23-1.115 0-.218-.023-.437-.08-.643a1.919 1.919 0 00-.265-.587 2.064 2.064 0 00-.46-.494 2.364 2.364 0 00-.7-.356 2.407 2.407 0 001.022-.862c.115-.172.195-.345.253-.529.057-.184.08-.367.08-.551 0-.414-.069-.782-.207-1.104a2.046 2.046 0 00-.586-.793c-.23-.218-.54-.38-.885-.494a4.189 4.189 0 00-1.172-.16c-.414 0-.793.057-1.15.183a2.924 2.924 0 00-.907.517 2.584 2.584 0 00-.586.77 2.29 2.29 0 00-.207.977h1.494c0-.195.034-.368.103-.517.07-.15.161-.287.288-.39.126-.104.264-.196.436-.254.173-.057.345-.092.552-.092.46 0 .805.115 1.023.357.218.23.333.563.333.988 0 .207-.034.39-.092.563a1 1 0 01-.287.425 1.456 1.456 0 01-.471.276 1.933 1.933 0 01-.667.104h-.885v1.184h.885c.253 0 .483.023.69.08.207.057.379.15.517.264.138.127.253.276.333.46.08.184.115.402.115.655 0 .471-.138.828-.402 1.07-.264.263-.632.378-1.092.378zm9.827-6.804a3.828 3.828 0 00-1.31-.885c-.495-.207-1.058-.31-1.678-.31H13.83v9.195h2.644c.632 0 1.218-.104 1.735-.31a3.789 3.789 0 001.333-.874c.368-.38.655-.84.85-1.368.196-.54.3-1.138.3-1.804v-.46c0-.667-.104-1.264-.3-1.804-.206-.54-.493-1-.861-1.38zm-.448 3.632c0 .483-.058.908-.161 1.299-.115.38-.276.713-.495.977a2.149 2.149 0 01-.816.609 2.952 2.952 0 01-1.137.207h-1.046v-6.632h1.115c.827 0 1.46.265 1.884.793.437.529.656 1.288.656 2.287v.46zM13.83.023l-.758.035 4.379 4.379 1.528-1.529c3.758 1.782 6.448 5.425 6.85 9.746h1.724C26.978 5.586 21.06.024 13.83.024z'
      ></path>
    </svg>
  )
}
