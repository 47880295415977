import { LineCurve3, Matrix4, Shape, Vector3 } from 'three'
import {
  findIslands,
  getShapeFromVertices,
  getVerticesFromIsland,
  gridDownSampler,
} from './matrix-processor'
import { UClass, VClass } from '../geometry-engine/geometry-definitions'
import {
  CompleteObjectDefinition,
  PartialObjectDefinition,
  modelSubTypes,
  modelTypes,
} from '../geometry-engine/mesh-maker'

export function getIslandsFromGrid(
  grid: number[][],
  baseCellSize: number,
  brushSize: number
) {
  const downSampledGrid = gridDownSampler(grid, baseCellSize, brushSize)

  const islands = findIslands(downSampledGrid)

  return islands
}

export function covnertGridToUFunction(
  grid: number[][],
  baseCellSize: number,
  brushSize: number
) {
  const islands = getIslandsFromGrid(grid, baseCellSize, brushSize)
  return islands
}

export function convertGridToShapes(
  grid: number[][],
  baseCellSize: number,
  brushSize: number,
  radiusRatio: number
) {
  const downSampledGrid = gridDownSampler(grid, baseCellSize, brushSize)
  const islands = findIslands(downSampledGrid)

  const islandVertices = islands.map((island) =>
    getVerticesFromIsland(island, downSampledGrid)
  )

  return islandVertices.map((vertices) =>
    getShapeFromVertices(vertices, brushSize, radiusRatio)
  )
}

export function getLongestShape(shapes: Shape[]) {
  return shapes.reduce((acc, shape) => {
    const shapeCurveLengths = shape.getCurveLengths()
    const shapeLength = shapeCurveLengths[shapeCurveLengths.length - 1]

    const accCurveLengths = acc.getCurveLengths()
    const accLength = accCurveLengths[accCurveLengths.length - 1]

    return shapeLength > accLength ? shape : acc
  }, shapes[0])
}

export function convertShapeToUDefinition(shape: Shape) {
  const definition = (u: number) => {
    const point = shape.getPointAt(u)
    return new Vector3(point.x, point.y, 0)
  }

  return new UClass(definition, { start: 0, end: 1 }, 0.001)
}

export function getVDefinitionFromLength(length: number) {
  const lineCurve = new LineCurve3(
    new Vector3(1, 0, 0),
    new Vector3(1, length, 0)
  )

  const definition = (v: number) => {
    return lineCurve.getPointAt(v)
  }

  return new VClass(definition, { start: 0, end: 1 }, 1)
}

export function functionCreateObjectDefinition(
  uObject: UClass,
  vObject: VClass,
  color: string,
  baseGridCellSize: number,
  waterdropGridSize: { x: number; y: number },
  height: number
) {
  const xPosition = baseGridCellSize / 2 - waterdropGridSize.x / 2
  const yPosition = -1 * (baseGridCellSize / 2 - waterdropGridSize.y / 2)

  const transformationMatrix = new Matrix4()

  const cos = Math.cos
  const sin = Math.sin
  const PI = Math.PI

  //prettier-ignore
  transformationMatrix.set(
    1, 0, 0, xPosition,
    0, cos(PI), -sin(PI), yPosition,
    0, sin(PI), cos(PI), height,
    0, 0, 0, 1
  )

  const partialObject = new PartialObjectDefinition(
    modelSubTypes.shell,
    [
      { v: 0, uClass: uObject },
      { v: 1, uClass: uObject },
    ],
    vObject
  )

  return new CompleteObjectDefinition(
    modelTypes.waterdrop,
    color,
    [partialObject],
    transformationMatrix
  )
}
