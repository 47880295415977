import { State } from '@hookstate/core'
import { geometryStateObject } from './geometry-engine/geometry-state'
import { waterdropStateObject } from './waterdrop-engine/waterdrop-state'
import { v4 as uuidv4 } from 'uuid'
import { NextRouter } from 'next/router'
import { websiteStateObject } from './website-state'
import { ToastContainer, toast } from 'react-toastify'

type stateType = typeof waterdropStateObject | typeof geometryStateObject

const VERSION = '1.0'
export function serializeWaterdropState(
  version: string,
  uuid: string,
  waterdropStateToBeSerialized: typeof waterdropStateObject
) {
  const jsonObject = {
    _id: uuid,
    type: waterdropStateToBeSerialized.type,
    version: version,
    waterdropGridSize: waterdropStateToBeSerialized.waterdropGridSize,
    baseGridCellSize: waterdropStateToBeSerialized.baseGridCellSize,
    baseGrid: waterdropStateToBeSerialized.baseGrid,
    minBrushSize: waterdropStateToBeSerialized.minBrushSize,
    brushSizeRatio: waterdropStateToBeSerialized.brushSizeRatio,
    radiusRatio: waterdropStateToBeSerialized.radiusRatio,
    height: waterdropStateToBeSerialized.height,
    modelColor: waterdropStateToBeSerialized.modelColor,
  }

  return jsonObject
}

export function serialize(stateToBeSerialized: stateType, uuid: string) {
  const version = VERSION
  if (stateToBeSerialized.type === 'waterdrop')
    return JSON.stringify(
      serializeWaterdropState(
        version,
        uuid,
        stateToBeSerialized as typeof waterdropStateObject
      )
    )
}

export function deserializeAndSetState(
  stateToBeDeserialized: string | Object,
  geometryStateHook: State<typeof geometryStateObject, {}>,
  waterdropStateHook: State<typeof waterdropStateObject, {}>
) {
  let jsonObject: any = {}

  if (
    typeof stateToBeDeserialized === 'string' ||
    stateToBeDeserialized instanceof String
  )
    jsonObject = JSON.parse(stateToBeDeserialized as string)
  else jsonObject = stateToBeDeserialized as Object

  if (jsonObject.type === 'waterdrop')
    waterdropDeserialize(jsonObject, waterdropStateHook)
}

/*
Functions below will be versioned deserialisation functions. The target will be not make any new schema that breaks the old one.  
*/

function waterdropDeserialize(
  jsonObject: any,
  waterdropStateHook: State<typeof waterdropStateObject, {}>
) {
  if (jsonObject.version === '1.0') {
    const stateObject = get_v1_0_waterdropDeserializedState(jsonObject)
    waterdropStateHook.set(stateObject)
  }
}

export function get_v1_0_waterdropDeserializedState(jsonObject: any) {
  return jsonObject
}

export async function saveToDatabase(
  stateToBeSaved: stateType,
  uuid = uuidv4()
) {
  try {
    var res: any = await fetch(window.location.origin + '/api/urls', {
      method: 'POST',
      body: serialize(stateToBeSaved, uuid),
    })

    console.log('url saved: ' + uuid)
  } catch (e) {
    console.log('error', e)
    res = { status: 500 }
    throw 'Design could not be saved to the database'
  }

  if (res.status !== 200) {
    console.log(res)
    throw 'Design could not be saved to the database'
  } else {
    return uuid
  }
}

export async function getDesignFromURLAndSetState(
  id: string,
  geometryState: State<typeof geometryStateObject>,
  waterdropState: State<typeof waterdropStateObject>
) {
  const fetchPromise = fetch(window.location.origin + '/api/urls?id=' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  try {
    toast.promise(fetchPromise, {
      pending: 'Please wait while we load your design from the database',
      success: 'Design loaded',
      error:
        "We couldn't find your design. Please check your internet connection",
    })
    const res = await fetchPromise

    const allPosts = await res.json()

    if (allPosts.data.length !== 0) {
      deserializeAndSetState(allPosts.data[0], geometryState, waterdropState)
      return true
    } else {
      toast.error(
        "We couldn't find your design. Please check your internet connection"
      )
      return false
    }
  } catch (e) {
    toast.error(
      "We couldn't find your design. Please check your internet connection"
    )
    return false
  }
}
