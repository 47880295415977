import React from 'react'

export default function QuestionIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      version='1.1'
      viewBox='0 0 18 29'
    >
      <path
        fill='currentColor'
        stroke='none'
        strokeWidth='1.05'
        d='M9.524 12.23l.007-.004a13.833 13.833 0 001.63-1.199 5.55 5.55 0 001.219-1.462l.002-.004c.32-.576.477-1.213.477-1.898 0-.729-.178-1.392-.552-1.97a3.632 3.632 0 00-1.474-1.305 4.351 4.351 0 00-1.97-.447c-.696 0-1.358.153-1.98.458a3.846 3.846 0 00-1.556 1.352c-.328.48-.527 1.036-.612 1.657H.055c.114-1.51.532-2.741 1.226-3.716l.001-.002c.794-1.125 1.844-1.96 3.16-2.513l.003-.001C5.778.608 7.257.32 8.89.32c1.81 0 3.387.295 4.743.87l.003.001c1.352.565 2.394 1.377 3.147 2.432.742 1.04 1.125 2.308 1.125 3.832 0 1.02-.172 1.907-.504 2.669l-.002.002a6.475 6.475 0 01-1.39 2.047 11.116 11.116 0 01-2.189 1.648c-.772.452-1.422.931-1.942 1.442l-.004.004a5.157 5.157 0 00-1.181 1.828l-.002.007c-.247.677-.37 1.48-.385 2.397H6.077c.014-1.555.157-2.762.414-3.64.273-.9.66-1.596 1.145-2.113a9.13 9.13 0 011.888-1.516zm-4.04 13.367v-.01c-.007-.758.257-1.393.81-1.937.562-.545 1.225-.812 2.019-.812.74 0 1.388.262 1.967.816.572.547.852 1.183.861 1.936-.009.511-.146.971-.41 1.392l-.002.003a3.066 3.066 0 01-1.039 1.023c-.419.243-.874.365-1.377.365-.793 0-1.456-.266-2.018-.81-.553-.553-.818-1.199-.81-1.966z'
      ></path>
    </svg>
  )
}
