import {
  BufferGeometry,
  CatmullRomCurve3,
  DoubleSide,
  Float32BufferAttribute,
  Matrix4,
  Mesh,
  MeshStandardMaterial,
  Scene,
  Vector3,
} from 'three'
import { UClass, VClass } from './geometry-definitions'

export enum editorTypeFlags {
  isSplineBased,
  isIneBased,
  isSketchBased,
  shouldShowAdvancedEditor,
  shouldTaperModifier,
  shouldHaveSplineScaler,
  shouldHaveTaperScaler,
  showWidthInSplineScaler,
  showHeightInSplineScaler,
  isWaterdropBased,
}

export enum modelSubTypes {
  socket,
  shell,
  boolAdd,
  boolSub,
}

export class EditorType {
  id: string
  name: string
  flags: editorTypeFlags[]

  constructor(id: string, name: string, flags: editorTypeFlags[]) {
    this.id = id
    this.name = name
    this.flags = flags
  }
}

export const editorTypes = {
  waterdrop: new EditorType('waterdrop', 'Waterdrop', [
    editorTypeFlags.isWaterdropBased,
  ]),
}

export class ModelType {
  id: string
  displayName: string
  editorType: EditorType
  minHeight: number
  bottomHoleSize: number
  bottomMinWidth: number
  cameraPosition: { position: Vector3; zoom: number }
  defaultModelOrientationVector: Vector3

  constructor(
    id: string,
    displayName: string,
    editorType: EditorType,
    minHeight: number, //all length units are in mm
    bottomHoleSize: number,
    bottomMinWidth: number,
    cameraPosition: { position: Vector3; zoom: number },
    defaultModelOrientationVector: Vector3
  ) {
    this.id = id
    this.displayName = displayName
    this.editorType = editorType
    this.minHeight = minHeight
    this.bottomHoleSize = bottomHoleSize
    this.bottomMinWidth = bottomMinWidth
    this.cameraPosition = cameraPosition
    this.defaultModelOrientationVector = defaultModelOrientationVector
  }
}

export const modelTypes = {
  waterdrop: new ModelType(
    'waterdrop',
    'Waterdrop',
    editorTypes.waterdrop,
    100,
    0,
    0,
    { position: new Vector3(0, 0, 0), zoom: 1 },
    new Vector3(0, 0, 1)
  ),
}
export class PartialObjectDefinition {
  type: modelSubTypes
  uDefinitions: { v: number; uClass: UClass }[]
  vDefinition: VClass
  transformation: Matrix4

  constructor(
    type: modelSubTypes,
    uDefinitions: { v: number; uClass: UClass }[],
    vDefinition: VClass,
    transformation = new Matrix4().identity()
  ) {
    this.type = type
    this.uDefinitions = uDefinitions
    this.vDefinition = vDefinition
    this.transformation = transformation
  }
}
export class CompleteObjectDefinition {
  modelType: ModelType
  subObjects: PartialObjectDefinition[]
  color: string
  transformation: Matrix4

  constructor(
    modelType: ModelType,
    color: string,
    subObjects: PartialObjectDefinition[],
    transformation = new Matrix4().identity()
  ) {
    this.modelType = modelType
    this.subObjects = subObjects
    this.color = color
    this.transformation = transformation
  }

  getPoint(u: number, v: number): Vector3 {
    const shell = this.subObjects.filter(
      (elem) => elem.type === modelSubTypes.shell
    )[0]

    const points = shell.uDefinitions.map((elem) => ({
      v: elem.v,
      uPoint: elem.uClass.getPoint(u),
    }))

    const transformedPoints = points.map((elem) => {
      const vPoint = shell.vDefinition.getPoint(elem.v)

      return new Vector3(elem.uPoint.x, elem.uPoint.y, vPoint.y)
    })

    const curve = new CatmullRomCurve3(transformedPoints, false)

    return curve.getPointAt(v)
  }

  getPointsLayerWise(uResolution: number, vResolution: number) {
    const points: Vector3[][] = []

    for (let v = 0; v < 1; v += vResolution) {
      points.push([])
      const lastLayerPoints = points[points.length - 1]

      for (let u = 0; u < 1; u += uResolution) {
        lastLayerPoints.push(this.getPoint(u, v))
      }
      lastLayerPoints.push(this.getPoint(1, v))
    }

    //This is just to ensure that both u = 1 and v = 1 are always included in the geometry
    points.push([])
    const lastLayerPoints = points[points.length - 1]
    for (let u = 0; u < 1; u += uResolution) {
      lastLayerPoints.push(this.getPoint(u, 1))
    }
    lastLayerPoints.push(this.getPoint(1, 1))

    return points
  }

  getPlanarGeometry(uResolution: number, vResolution: number) {
    const layerWisePoints = this.getPointsLayerWise(uResolution, vResolution)

    const vertices: number[] = []

    for (let loopIndex = 0; loopIndex < layerWisePoints.length; loopIndex++) {
      const nextLoopIndex =
        loopIndex < layerWisePoints.length - 1 ? loopIndex + 1 : 0

      const loop1 = layerWisePoints[loopIndex]
      const loop2 = layerWisePoints[nextLoopIndex]

      for (let vertexIndex = 0; vertexIndex < loop1.length; vertexIndex++) {
        const nextVertexIndex =
          vertexIndex < loop1.length - 1 ? vertexIndex + 1 : 0

        vertices.push(
          loop1[nextVertexIndex].x,
          loop1[nextVertexIndex].y,
          loop1[nextVertexIndex].z
        )
        vertices.push(
          loop2[vertexIndex].x,
          loop2[vertexIndex].y,
          loop2[vertexIndex].z
        )
        vertices.push(
          loop1[vertexIndex].x,
          loop1[vertexIndex].y,
          loop1[vertexIndex].z
        )

        vertices.push(
          loop2[nextVertexIndex].x,
          loop2[nextVertexIndex].y,
          loop2[nextVertexIndex].z
        )
        vertices.push(
          loop2[vertexIndex].x,
          loop2[vertexIndex].y,
          loop2[vertexIndex].z
        )
        vertices.push(
          loop1[nextVertexIndex].x,
          loop1[nextVertexIndex].y,
          loop1[nextVertexIndex].z
        )
      }
    }

    const geometry = new BufferGeometry()

    geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3))
    geometry.computeVertexNormals()

    return geometry
  }

  getMesh(uResolution: number, vResolution: number) {
    const planarGeometry = this.getPlanarGeometry(uResolution, vResolution)

    let newMaterial = new MeshStandardMaterial({
      color: parseInt(this.color, 16),
      metalness: 0.2,
      roughness: 0.5,
    })

    newMaterial.side = DoubleSide

    const objectMesh = new Mesh(planarGeometry, newMaterial)
    objectMesh.castShadow = true
    objectMesh.receiveShadow = true

    objectMesh.geometry.applyMatrix4(this.transformation)
    return objectMesh
  }

  getARScene(uResolution: number, vResolution: number) {
    const exportMesh = this.getMesh(uResolution, vResolution)

    const scene = new Scene()

    scene.add(exportMesh)

    scene.scale.set(0.001, 0.001, 0.001)
    scene.updateMatrixWorld(true)

    return scene
  }

  getPrice(uResolution: number, vResolution: number) {
    const layerWisePoints = this.getPointsLayerWise(uResolution, vResolution)
    const multiplier = 0.000545

    const exactPrice = layerWisePoints.reduce(
      (totalAcc, layer) => {
        const layerTotal = layer.reduce(
          (curLayerAcc, point) => {
            const distanceFromPrevPoint = point.distanceTo(
              curLayerAcc.lastPoint
            )
            const price = distanceFromPrevPoint * multiplier
            return { lastPoint: point, total: curLayerAcc.total + price }
          },
          { lastPoint: layer[0], total: 0 }
        )

        return {
          lastPoint: layer[0],
          total:
            totalAcc.total +
            layerTotal.total * layer[0].distanceTo(totalAcc.lastPoint),
        }
      },
      { lastPoint: layerWisePoints[0][0], total: 0 }
    )

    const minPrice = 149.99
    return Math.max(Math.ceil(exactPrice.total) - 0.01, minPrice)
  }
}
