import QuestionIcon from 'components/icons/question'
import SvgButtonTransparent from './svg-button-transparent'
import { State } from '@hookstate/core'
import { websiteStateObject } from 'lib/website-state'
import { useTour } from '@reactour/tour'

export default function QuestionButton({
  className,
  active,
  websiteState,
}: {
  className?: string
  active?: boolean
  websiteState: State<typeof websiteStateObject, {}>
}) {
  const { setIsOpen, setCurrentStep } = useTour()
  const {
    threeDActive,
    userOnboarding: { allowNavigation },
  } = websiteState

  const startTutorial = () => {
    setTimeout(() => {
      setCurrentStep(0)
      setIsOpen(true)
    }, 1000)

    threeDActive.set(false)
    allowNavigation.set(true)
  }

  return (
    <SvgButtonTransparent
      id='help_button'
      className={className}
      active={active}
      onClick={startTutorial}
    >
      <QuestionIcon />
    </SvgButtonTransparent>
  )
}
