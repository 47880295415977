import CamControlsThree from 'camera-controls'

export const mouseStateNoRotate = {
  left: CamControlsThree.ACTION.NONE,
  right: CamControlsThree.ACTION.TRUCK,
  middle: CamControlsThree.ACTION.ZOOM,
  wheel: CamControlsThree.ACTION.ZOOM,
}

export const mouseStateRotate = {
  left: CamControlsThree.ACTION.ROTATE,
  right: CamControlsThree.ACTION.TRUCK,
  middle: CamControlsThree.ACTION.ZOOM,
  wheel: CamControlsThree.ACTION.ZOOM,
}

export const mouseStateNoZoom = {
  left: CamControlsThree.ACTION.ROTATE,
  right: CamControlsThree.ACTION.NONE,
  middle: CamControlsThree.ACTION.NONE,
  wheel: CamControlsThree.ACTION.NONE,
}

export const touchStateNoRotate = {
  one: CamControlsThree.ACTION.NONE,
  two: CamControlsThree.ACTION.TOUCH_TRUCK,
  three: CamControlsThree.ACTION.NONE,
}

export const touchStateRotate = {
  one: CamControlsThree.ACTION.TOUCH_ROTATE,
  two: CamControlsThree.ACTION.TOUCH_TRUCK,
  three: CamControlsThree.ACTION.NONE,
}

export const touchStateNoZoom = {
  one: CamControlsThree.ACTION.TOUCH_ROTATE,
  two: CamControlsThree.ACTION.TOUCH_TRUCK,
  three: CamControlsThree.ACTION.NONE,
}
