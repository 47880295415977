export default function AedittoLogo() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={'100%'}
      height={'100%'}
      viewBox='0 0 375 310'
    >
      <style>
        {'.st0,.st1{fill:#fff}.st1{fill-rule:evenodd;clip-rule:evenodd}'}
      </style>
      <path d='M265.2 137.1c-3-4-7.9-6.1-13.1-6.1-7.9 0-14.4 4.6-17.3 11.3-2.7-6.1-8.6-11.3-17.4-11.3-8.1 0-14.6 4.5-17.6 11.1-2.9-6.7-10-11.1-17.8-11.1-10.9 0-19.5 8.4-19.5 19.1 0 10.7 8.3 19.1 18.9 19.1 4.9 0 9.6-1.9 12.7-5.6v4.7h6.7v-8.1c3.3 5.4 9.3 9 16.7 9 7 0 12-3 15.6-7.6l-5.2-4c-2.2 2.9-5.7 5.2-10.4 5.2-6.1 0-11.2-4-12.4-10h28.1c1.3 9.4 9.3 16.5 19.3 16.5 10.8 0 19.2-7.7 19.2-19.2v-27.7H265v14.7zm-83.3 25.7c-7 0-12.5-5.6-12.5-12.7s5.6-12.6 12.6-12.6c7.1 0 12.6 5.6 12.6 12.6 0 7.1-5.6 12.7-12.7 12.7zm23.6-16.6c1.5-5.2 6.1-8.9 11.9-8.9 5.7 0 10.5 3.6 11.6 8.9h-23.5zm47.1 16.6c-7 0-12.6-5.6-12.6-12.7s5.5-12.7 12.5-12.7c7.1 0 12.7 5.6 12.7 12.7s-5.6 12.7-12.6 12.7zm22.4-40.5h6.7v6.3H275v-6.3zm0 9.6h6.7v36.5H275v-36.5zm48.8-.9c-9.5-.1-17.2 6.4-19 15.2h-3.2v-23.9h-6.7v23.9h-3.4v-23.9h-6.7v39.3c0 4.4 2.5 7 6.5 7h.3v-16h3.4v9c0 4.4 2.5 7 6.5 7h.3v-16h3c1.2 9.4 9.2 16.5 19.2 16.5 10.8 0 19.4-8.4 19.4-19.1-.3-10.5-8.8-19.1-19.6-19zm0 31.7c-7.1 0-12.6-5.6-12.6-12.7 0-7 5.6-12.6 12.6-12.6s12.6 5.6 12.6 12.6c0 7.2-5.5 12.7-12.6 12.7zm16.5-40.7c-2.6 0-4.7 2-4.7 4.6 0 2.6 2.1 4.7 4.7 4.7s4.7-2 4.7-4.7c0-2.6-2.1-4.6-4.7-4.6zm0 8.2c-2.1 0-3.6-1.6-3.6-3.6s1.6-3.6 3.6-3.6c2.1 0 3.6 1.6 3.6 3.6 0 2.1-1.6 3.6-3.6 3.6zm1.9-4.4c0-1-.7-1.5-1.6-1.5h-1.9v4.5h1v-1.5h.8l.6 1.5h1.1l-.8-1.7c.5-.2.8-.7.8-1.3zm-1.8.7h-.8v-1.3h.8c.4 0 .7.3.7.7.1.3-.2.6-.7.6z' />
      <path
        d='M85.6 168.3h6.5v-45.9h-6.5v45.9zm60.7-17.2-12.1-4.8c-.9-.4-.9-1.6 0-2l12-5.1 1-.4-.4-1-1.6-3.6-.4-1-1 .4-12 5.1c-.9.4-1.8-.5-1.4-1.4l4.8-12.1.4-1-1-.4-3.7-1.5-1-.4-.4 1-4.8 12.1c-.4.9-1.6.9-2 0l-5.1-12-.4-1-1 .4-3.6 1.6-1 .4.4 1 5.1 12c.4.9-.5 1.8-1.4 1.4l-12.1-4.8-1-.4-.4 1-1.5 3.7-.4 1 1 .4 12.1 4.8c.9.4.9 1.6 0 2l-12 5.1-1 .4.4 1 1.6 3.6.4 1 1-.4 12-5.1c.9-.4 1.8.5 1.4 1.4l-4.8 12.1-.4 1 1 .4 3.7 1.5 1 .4.4-1 4.8-12.1c.4-.9 1.6-.9 2 0l5.1 12 .4 1 1-.4 3.6-1.6 1-.4-.4-1-5.1-12c-.4-.9.5-1.8 1.4-1.4l12.1 4.8 1 .4.4-1 1.5-3.7.4-1-1-.4zm-92.4 17.8c13.4 0 23.9-10.4 23.9-23.6 0-13.2-10.5-23.8-23.9-23.6-13.3-.1-23.9 10.5-23.9 23.7 0 13.1 10.6 23.5 23.9 23.5z'
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        }}
      />
    </svg>
  )
}
