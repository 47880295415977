import React from 'react'

export default function RotateQuestion() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 29 29'
    >
      <mask
        id='mask0_18_3611'
        style={{ maskType: 'alpha' }}
        width='29'
        height='29'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path fill='currentColor' d='M0.783 0.148H28.777V28.142H0.783z'></path>
      </mask>
      <g mask='url(#mask0_18_3611)'>
        <path
          fill='currentColor'
          d='M14.78 26.975c-2.178 0-4.18-.496-6.007-1.487-1.828-.991-3.325-2.313-4.491-3.966v3.12H1.949v-6.998h6.999v2.333H6.06c.933 1.4 2.162 2.527 3.688 3.382 1.526.856 3.203 1.283 5.03 1.283 1.459 0 2.824-.277 4.098-.83a10.672 10.672 0 003.324-2.246 10.669 10.669 0 002.245-3.324c.554-1.274.831-2.64.831-4.097h2.333c0 1.769-.335 3.43-1.006 4.986a12.986 12.986 0 01-2.756 4.082 12.986 12.986 0 01-4.082 2.756c-1.555.67-3.217 1.006-4.986 1.006zM1.95 14.145c0-1.77.335-3.431 1.005-4.987a12.986 12.986 0 012.756-4.082A12.985 12.985 0 019.793 2.32c1.555-.67 3.218-1.006 4.987-1.006 2.177 0 4.18.496 6.007 1.488 1.827.991 3.324 2.313 4.49 3.965v-3.12h2.333v6.999h-6.998V8.313h2.886c-.933-1.4-2.162-2.527-3.688-3.383-1.526-.855-3.203-1.283-5.03-1.283-1.458 0-2.824.277-4.097.831a10.67 10.67 0 00-3.325 2.246 10.67 10.67 0 00-2.245 3.324 10.156 10.156 0 00-.831 4.097H1.949zm12.83 6.998c.408 0 .753-.14 1.035-.423.282-.281.423-.627.423-1.035 0-.408-.141-.758-.423-1.05a1.384 1.384 0 00-1.035-.437c-.409 0-.754.14-1.036.423a1.408 1.408 0 00-.422 1.035c0 .408.14.758.422 1.05.282.291.627.437 1.036.437zm-1.05-4.432h2.128c0-.7.083-1.225.248-1.575.166-.35.5-.777 1.006-1.283.68-.68 1.133-1.23 1.356-1.647.224-.418.336-.919.336-1.502 0-1.089-.39-1.954-1.167-2.595-.777-.642-1.73-.963-2.857-.963-.972 0-1.808.253-2.508.759-.7.505-1.206 1.224-1.517 2.157l1.925.817c.136-.506.394-.924.773-1.254.379-.33.821-.496 1.327-.496.524 0 .967.15 1.326.452.36.302.54.705.54 1.21 0 .33-.078.661-.233.992-.156.33-.486.72-.992 1.166-.641.564-1.084 1.113-1.327 1.648-.243.534-.364 1.24-.364 2.114z'
        ></path>
      </g>
    </svg>
  )
}
