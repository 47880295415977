import { motion } from 'framer-motion'
import {
  animateStateOpacityRotation,
  exitStateOpacityRotation,
  initialStateOpacityRotation,
  springMotionLowStiffness,
  whileHover,
  whileTap,
} from 'lib/framer-motion-constants'
import { toast } from 'react-toastify'
import { twMerge } from 'tailwind-merge'

export default function SvgButtonRound({
  id,
  className,
  children,
  active,
  onClick,
  message,
}: {
  id?: string
  className?: string
  children?: React.ReactNode
  active?: boolean
  onClick?: () => void
  message?: string
}) {
  const iconColor = active ? 'bg-accent-light' : 'bg-primaryText-light'

  return (
    //  @ts-ignore
    <motion.button
      id={id}
      className={twMerge(
        iconColor + ' text-secondaryText-light p-3 w-14 h-14 rounded-[50%] ',
        className
      )}
      onClick={() => {
        if (onClick) onClick()
        if (message) toast(message, { autoClose: 1000 })
      }}
      initial={initialStateOpacityRotation}
      animate={animateStateOpacityRotation}
      exit={exitStateOpacityRotation}
      whileHover={whileHover}
      whileTap={whileTap}
      transition={springMotionLowStiffness}
    >
      {children}
    </motion.button>
  )
}
