import { State, useHookstate } from '@hookstate/core'
import { CameraControls, Environment, Html, Text3D } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { useGesture } from '@use-gesture/react'
import { geometryState } from 'lib/geometry-engine/geometry-state'
import { CompleteObjectDefinition } from 'lib/geometry-engine/mesh-maker'
import roboto from 'lib/roboto_light_regular'
import {
  convertGridToShapes,
  convertShapeToUDefinition,
  functionCreateObjectDefinition,
  getIslandsFromGrid,
  getLongestShape,
  getVDefinitionFromLength,
} from 'lib/waterdrop-engine/geomery-processor'
import {
  absolutePointToCellIndex,
  getListOfAffectedIndexes,
  gridDownSampler,
} from 'lib/waterdrop-engine/matrix-processor'
import { waterdropState } from 'lib/waterdrop-engine/waterdrop-state'
import {
  RefContext,
  waterdrop_brushType,
  websiteState,
} from 'lib/website-state'
import {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as THREE from 'three'
import { useElementSize } from 'usehooks-ts'
import {
  mouseStateNoRotate,
  mouseStateRotate,
  touchStateNoRotate,
  touchStateRotate,
} from './camera-controls'

function CameraUpdater({
  cameraControlsRef,
  threeDViewActive,
  zoomFactor,
}: {
  cameraControlsRef: MutableRefObject<CameraControls | null>
  threeDViewActive: boolean
  zoomFactor: number
}) {
  useEffect(() => {
    if (!cameraControlsRef.current) return
    if (!threeDViewActive) {
      cameraControlsRef.current!.reset(true)
    } else {
      cameraControlsRef.current!.saveState()
      cameraControlsRef.current!.moveTo(1100, 200, 1100, true)
      cameraControlsRef.current!.setTarget(0, 0, 0, true)
    }
  }, [threeDViewActive])

  useEffect(() => {
    cameraControlsRef.current!.zoomTo(zoomFactor)
  }, [zoomFactor])
  return <></>
}

function BaseGridDebug() {
  const { baseGrid, baseGridCellSize, waterdropGridSize } =
    useHookstate(waterdropState)

  return baseGrid.get().flatMap((row, jIndex) => {
    return row.flatMap((cell, iIndex) => {
      const xPosition =
        iIndex * baseGridCellSize.get() +
        baseGridCellSize.get() / 2 -
        waterdropGridSize.get().x / 2
      const yPosition =
        -1 *
        (jIndex * baseGridCellSize.get() +
          baseGridCellSize.get() / 2 -
          waterdropGridSize.get().y / 2)
      if (cell === 1)
        return (
          <mesh position={[xPosition, yPosition, 0]}>
            <planeGeometry
              args={[baseGridCellSize.get(), baseGridCellSize.get()]}
            ></planeGeometry>
            <meshStandardMaterial color={'black'} />
          </mesh>
        )
      else <></>
    })
  })
}

function DownSampledGridDebug() {
  const {
    baseGrid,
    baseGridCellSize,
    waterdropGridSize,
    brushSizeRatio,
    minBrushSize,
  } = useHookstate(waterdropState)

  const brushSize = brushSizeRatio.get() * minBrushSize.get()
  const downSampledGrid = gridDownSampler(
    //@ts-ignore
    baseGrid.get(),
    baseGridCellSize.get(),
    brushSize
  )

  return downSampledGrid.flatMap((row) =>
    row.flatMap((node) => {
      const xPosition = node.position.x - waterdropGridSize.get().x / 2
      const yPosition = -1 * (node.position.y - waterdropGridSize.get().y / 2)

      if (node.value === 1)
        return (
          <mesh position={[xPosition, yPosition, 0]}>
            <planeGeometry args={[brushSize, brushSize]}></planeGeometry>
            <meshStandardMaterial color={'red'} />
          </mesh>
        )
      else <></>
    })
  )
}

function IslandDebug() {
  const {
    baseGrid,
    baseGridCellSize,
    waterdropGridSize,
    brushSizeRatio,
    minBrushSize,
  } = useHookstate(waterdropState)

  const brushSize = brushSizeRatio.get() * minBrushSize.get()

  const islands = getIslandsFromGrid(
    //@ts-ignore
    baseGrid.get(),
    baseGridCellSize.get(),
    brushSize
  )
  console.log(islands)

  return (
    <group>
      {islands.flatMap((island) =>
        island.nodes.flatMap((node) => {
          const xPosition = node.position.x - waterdropGridSize.get().x / 2
          const yPosition =
            -1 * (node.position.y - waterdropGridSize.get().y / 2)

          if (node.value === 1)
            return (
              <mesh position={[xPosition, yPosition, 0]}>
                <planeGeometry args={[brushSize, brushSize]}></planeGeometry>
                <meshStandardMaterial color={'black'} />
              </mesh>
            )
          else <></>
        })
      )}
    </group>
  )
}

function createAndSetMeshObjects(
  shapes: THREE.Shape[],
  objects: State<CompleteObjectDefinition[], {}>,
  color: string,
  height: number,
  baseGridCellSize: number,
  waterdropGridSize: { x: number; y: number }
) {
  const longestShape = getLongestShape(shapes)

  if (longestShape) {
    const uObject = convertShapeToUDefinition(longestShape)
    const vObject = getVDefinitionFromLength(height)
    const completeObject = functionCreateObjectDefinition(
      uObject,
      vObject,
      color,
      baseGridCellSize,
      waterdropGridSize,
      height
    )

    objects.set([completeObject])
  }
}

function IslandShapesAndMeasurementBoxes() {
  const {
    baseGrid,
    baseGridCellSize,
    waterdropGridSize,
    brushSizeRatio,
    minBrushSize,
    radiusRatio,
    height,
    modelColor,
  } = useHookstate(waterdropState)
  const { objects } = useHookstate(geometryState)
  const { threeDActive, showMeasurements, atLeastOneObjectExists } =
    useHookstate(websiteState)

  const brushSize = brushSizeRatio.get() * minBrushSize.get()

  const shapes = convertGridToShapes(
    //@ts-ignore
    baseGrid.get(),
    baseGridCellSize.get(),
    brushSize,
    radiusRatio.get()
  )

  if (shapes.length > 0) atLeastOneObjectExists.set(true)
  else atLeastOneObjectExists.set(false)

  if (threeDActive.get())
    createAndSetMeshObjects(
      shapes,
      objects,
      modelColor.get(),
      height.get(),
      baseGridCellSize.get(),
      waterdropGridSize.get()
    )

  const xPosition = baseGridCellSize.get() / 2 - waterdropGridSize.get().x / 2
  const yPosition = baseGridCellSize.get() / 2 - waterdropGridSize.get().y / 2

  return (
    <>
      {!threeDActive.get() &&
        shapes.map((islandShape, index) => {
          const shapeGeometry = new THREE.ShapeGeometry(islandShape)

          const material = new THREE.MeshBasicMaterial({
            color: 0x000000,
          })

          material.side = THREE.BackSide
          const objectMesh = new THREE.Mesh(shapeGeometry, material)
          objectMesh.geometry.rotateX(Math.PI)
          objectMesh.geometry.translate(xPosition, -yPosition, 0)

          const box = new THREE.Box3()
          box.setFromObject(objectMesh)

          return (
            <group key={index}>
              <mesh
                //@ts-ignore
                geometry={shapeGeometry}
                key={index}
                material={material}
              />
              {!threeDActive.get() && showMeasurements.get() && (
                <box3Helper material={objectMesh.material} box={box} />
              )}
              {!threeDActive.get() &&
                showMeasurements.get() &&
                (['x', 'y'] as ('x' | 'y')[]).map((axis) => {
                  const size: number = box.max[axis] - box.min[axis]
                  let position = new THREE.Vector3(0, 0, 0)
                  const midPoint = box.min[axis] + size / 2
                  switch (axis) {
                    case 'x':
                      position = box.min.clone()
                      position.x = midPoint
                      position.y = box.max.y + 10
                      break
                    case 'y':
                      position = box.max.clone()
                      position.y = midPoint
                      position.x = position.x + 10
                      break
                  }
                  return (
                    <Text3D
                      key={axis}
                      font={roboto as any} //this was changed to any type because there seems to be a problem with ThreeJS' expected font
                      position={[position.x, position.y, position.z]}
                      size={10}
                    >
                      {size.toFixed(2) + 'mm'}
                      <meshLambertMaterial attach='material' color={'silver'} />
                    </Text3D>
                  )
                })}
            </group>
          )
        })}
    </>
  )
}

//TODO write a test for this function
export function setGridValues(
  absolutePosition: { x: number; y: number },
  grid: number[][],
  gridSize: { x: number; y: number },
  cellSize: number,
  brushSize: number,
  brushType: waterdrop_brushType
) {
  const indexOfBrushSizedGrid = absolutePointToCellIndex(
    absolutePosition,
    gridSize,
    brushSize
  )!

  const affectedIndex = indexOfBrushSizedGrid
    ? getListOfAffectedIndexes(
        {
          x: indexOfBrushSizedGrid.i * brushSize + brushSize / 2,
          y: indexOfBrushSizedGrid.j * brushSize + brushSize / 2,
        },
        gridSize,
        cellSize,
        brushSize
      )
    : []

  affectedIndex.forEach((elem) => {
    if (
      elem.i < grid[0].length &&
      elem.j < grid.length &&
      elem.i >= 0 &&
      elem.j >= 0
    ) {
      if (brushType === waterdrop_brushType.add) {
        grid[elem.j][elem.i] = 1
      } else grid[elem.j][elem.i] = 0
    }
  })

  return grid
}

function getEffectiveBrushType(
  altKey: boolean,
  brushType: waterdrop_brushType
) {
  if (!altKey) return brushType
  else if (brushType === waterdrop_brushType.add) return waterdrop_brushType.sub
  else return waterdrop_brushType.add
}

function MeshObjectsAndMeasurementBox() {
  const { objects } = useHookstate(geometryState)

  const { threeDActive, showMeasurements } = useHookstate(websiteState)

  return (
    <>
      {objects.get().map((elem, index) => {
        const objectMesh = elem.getMesh(0.001, 1)
        const box = new THREE.Box3()
        box.setFromObject(objectMesh)

        return (
          <group key={index}>
            <mesh
              geometry={objectMesh.geometry}
              material={objectMesh.material}
            />
            {threeDActive.get() && showMeasurements.get() && (
              <box3Helper material={objectMesh.material} box={box} />
            )}
            {threeDActive.get() &&
              showMeasurements.get() &&
              (['x', 'y', 'z'] as ('x' | 'y' | 'z')[]).map((axis) => {
                const size: number = box.max[axis] - box.min[axis]
                let position = new THREE.Vector3(0, 0, 0)
                const midPoint = box.min[axis] + size / 2
                switch (axis) {
                  case 'x':
                    position = box.min.clone()
                    position.x = midPoint
                    position.y = box.max.y + 10
                    break
                  case 'y':
                    position = box.max.clone()
                    position.y = midPoint
                    position.x = position.x + 10
                    break
                  case 'z':
                    position = box.max.clone()
                    position.z = midPoint
                    position.x = position.x + 10
                    break
                }
                return (
                  <Text3D
                    key={axis}
                    font={roboto as any} //this was changed to any type because there seems to be a problem with ThreeJS' expected font
                    position={[position.x, position.y, position.z]}
                    size={10}
                  >
                    {size.toFixed(2) + 'mm'}
                    <meshLambertMaterial attach='material' color={'silver'} />
                  </Text3D>
                )
              })}
          </group>
        )
      })}
    </>
  )
}

export default function HybridViewer() {
  const cameraControlsRef = useRef<CameraControls | null>(null)
  const editorRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null)

  const webState = useHookstate(websiteState)

  const { threeDActive, lastAddedPoint, atLeastOneObjectExists } = webState

  const {
    waterdropGridSize,
    baseGridCellSize,
    brushSizeRatio,
    minBrushSize,
    baseGrid,
  } = useHookstate(waterdropState)
  const { brushType } = webState

  const [cameraControlsActive, setCameraControlsActive] = useState(false)
  const [dollySpeed, setDollySpeed] = useState(1)
  const [hasMounted, setHasMounted] = useState(false)

  const lastMousePosition = useRef<{ x: number; y: number } | undefined>(
    undefined
  )

  const brushSize = brushSizeRatio.get() * minBrushSize.get()

  const drawingBoardGestures = useGesture(
    {
      onDrag: (state) => {
        const e = state.event

        // console.log(state.first, state.last, state.active)
        if (state.touches > 1) setCameraControlsActive(true)

        //do nothing when the screen is being zoomed, panned or is in 3d view
        if (
          !(threeDActive.get() || cameraControlsActive || state.touches > 1)
        ) {
          const target = e.target as HTMLDivElement
          const bounds = target.getBoundingClientRect()

          const mousePosition = {
            x:
              ((state.xy[0] - bounds.left) * target.offsetWidth) / bounds.width,
            y:
              ((state.xy[1] - bounds.top) * target.offsetHeight) /
              bounds.height,
          }

          let deltaValues = new THREE.Vector2(0, 0)

          if (lastMousePosition.current)
            deltaValues = new THREE.Vector2(
              mousePosition.x - lastMousePosition.current!.x,
              mousePosition.y - lastMousePosition.current!.y
            )

          let deltaDistance = Math.hypot(deltaValues.x, deltaValues.y)
          const deltaUnitVectorIntoCellSize = deltaValues
            .clone()
            .divideScalar(deltaDistance)
            .multiplyScalar(baseGridCellSize.get())

          let absolutePosition = new THREE.Vector2(
            mousePosition.x,
            mousePosition.y
          )

          const effectiveBrushType = getEffectiveBrushType(
            state.altKey,
            brushType.get()
          )

          const updateGridState = (position: { x: number; y: number }) => {
            baseGrid.set((currentState) => {
              const newState = [
                ...setGridValues(
                  position,
                  currentState,
                  waterdropGridSize.get(),
                  baseGridCellSize.get(),
                  brushSize,
                  effectiveBrushType
                ),
              ]
              return newState
            })
          }

          while (deltaDistance > baseGridCellSize.get()) {
            absolutePosition = absolutePosition.sub(deltaUnitVectorIntoCellSize)

            updateGridState(absolutePosition)

            deltaValues = deltaValues.sub(deltaUnitVectorIntoCellSize)
            deltaDistance = Math.hypot(deltaValues.x, deltaValues.y)
          }

          updateGridState(mousePosition)
          lastMousePosition.current = mousePosition
        }
        if (state.last) {
          lastMousePosition.current = undefined
          setCameraControlsActive(false)
        }
      },
    },

    {
      drag: { pointer: { buttons: [1] }, delay: 400 },
    }
  )

  const canvasGestures = useGesture(
    {
      onPinch: (state) => {
        cameraControlsRef.current?.zoomTo(state.offset[0])
      },
    },
    {
      pinch: { preventDefault: true },
    }
  )

  const { canvasRef }: { canvasRef: MutableRefObject<HTMLCanvasElement> } =
    useContext(RefContext)

  const [divRef, divSize] = useElementSize()
  const [zoomFactor, setZoomFactor] = useState(1)

  useEffect(() => {
    //644 was experimentally found to be the perfect size for 1 zoom factor
    setZoomFactor(divSize.height / 644)
  }, [divSize])

  return (
    <div
      className='h-[calc(100%-9rem)] py-3 relative'
      id='canvas'
      {...canvasGestures()}
      ref={divRef}
    >
      <Canvas
        gl={{ preserveDrawingBuffer: true }}
        ref={canvasRef}
        shadows
        orthographic
        color='black'
        style={{ zIndex: 0 }}
        onCreated={({ camera }) => {
          const newCamera = camera as THREE.OrthographicCamera
          newCamera.left = 200
          newCamera.right = -200
          newCamera.top = 200
          newCamera.bottom = -200
          newCamera.near = 1
          newCamera.far = 4000

          setHasMounted(true)
        }}
      >
        <Environment
          near={1}
          far={4000}
          resolution={256}
          files='/static/img/empty_warehouse_01_1k.hdr'
        />

        <CameraControls
          makeDefault
          ref={cameraControlsRef}
          mouseButtons={
            threeDActive.get() ? mouseStateRotate : mouseStateNoRotate
          }
          touches={threeDActive.get() ? touchStateRotate : touchStateNoRotate}
          truckSpeed={30}
          dollySpeed={dollySpeed}
        />
        <ambientLight intensity={1} />

        <Html
          transform
          occlude={'blending'}
          prepend
          style={{
            width: waterdropGridSize.get().x + 'px',
            height: waterdropGridSize.get().y + 'px',
            outline: '2px dashed',
            outlineColor: 'rgb(229,231,235)',
          }}
          className='bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] bg-[size:11px_11px]'
          distanceFactor={400}
          name='drawingBoard'
        >
          <div
            id='drawing_board'
            className='w-full h-full z-0 flex items-center justify-center '
            {...drawingBoardGestures()}
            ref={editorRef}
          >
            {!atLeastOneObjectExists.get() && (
              <div id='start_drawing'>Start Drawing Here</div>
            )}
          </div>
        </Html>

        <CameraUpdater
          cameraControlsRef={cameraControlsRef}
          threeDViewActive={threeDActive.get()}
          zoomFactor={zoomFactor}
        />

        <IslandShapesAndMeasurementBoxes />
        <MeshObjectsAndMeasurementBox />
        {/* <BaseGridDebug /> */}
        {/* <IslandDebug /> */}
        {/* <DownSampledGridDebug /> */}
      </Canvas>
    </div>
  )
}
