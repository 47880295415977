/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './app/**/*.{js,ts,jsx,tsx,mdx}',
    './pages/**/*.{js,ts,jsx,tsx,mdx}',
    './components/**/*.{js,ts,jsx,tsx,mdx}',

    // Or if using `src` directory:
    './src/**/*.{js,ts,jsx,tsx,mdx}',

    //For monorepo to wok
    './**/*.{js,ts,jsx,tsx,mdx}',
    '../../packages/components/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  theme: {
    extend: {
      colors: {
        primaryBackground: { light: '#ffffff' },
        secondaryBackground: { light: '#d9d9d9' },
        drawerBackgroundPrimary: { light: '#1a1a1a' },
        drawerBackgroundSecondary: { light: '#343434' },
        accent: { light: '#fe65fb' },
        primaryText: { light: '#000000' },
        secondaryText: { light: '#ffffff' },
        tertiaryText: { light: '#eaeaea' },
        tertiaryTextDarker: { light: '#9c9a9a' },
      },
      boxShadow: {
        custom_sm: '0px 2px 6px #AAA',
      },
      height: {
        screen: ['100vh', '100dvh'],
      },
    },
  },
  plugins: [],
}
