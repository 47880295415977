import { StepType } from '@reactour/tour'
import WelcomeContent from './welcome-content'
import DrawingBoard from './drawing-board'
import { State } from '@hookstate/core'
import { waterdropStateObject } from 'lib/waterdrop-engine/waterdrop-state'
import { geometryStateObject } from 'lib/geometry-engine/geometry-state'
import { getDesignFromURLAndSetState } from 'lib/serialisation-core'
import { websiteState, websiteStateObject } from 'lib/website-state'
import ThreeDViewContent from './3d-view-content'
import ThreeDButtonContent from './three-d-button-content'
import ThreeDViewButtonsContent from './3d-view-buttons-content'
import HelpButtonContent from './help-buton-content'
import { Dispatch, SetStateAction } from 'react'

export function getSteps(
  waterdropState: State<typeof waterdropStateObject>,
  geometryState: State<typeof geometryStateObject>,
  websiteState: State<typeof websiteStateObject>,
  setIsOpen: Dispatch<SetStateAction<Boolean>>
): StepType[] {
  return [
    {
      selector: '#start_drawing',
      content: <WelcomeContent />,
      position: 'center',
      styles: {
        highlightedArea: ({ x, y, width, height }) => {
          return { x, y, width: 0, height: 0 }
        },
      },
    },
    {
      selector: '#drawing_board',
      content: <DrawingBoard websiteState={websiteState} />,
      action: async (elem) => {
        websiteState.userOnboarding.allowNavigation.set(false)

        if (!websiteState.atLeastOneObjectExists.get()) {
          const loaded = await getDesignFromURLAndSetState(
            '6c90f684-ccda-4388-84ab-ae561363c146',
            geometryState,
            waterdropState
          )
        }

        websiteState.userOnboarding.allowNavigation.set(true)
      },
    },
    {
      selector: '#brush_type_button',
      content: 'With this button you can change the brush type (draw/erase)',
    },
    {
      selector: '#brush_size_button',
      content: 'Use this button to change the size of the brush',
    },
    {
      selector: '#three_d_button',
      content: <ThreeDButtonContent />,
      stepInteraction: true,
      action: () => {
        websiteState.threeDActive.set(false)
        websiteState.userOnboarding.allowNavigation.set(false)
      },
      actionAfter: () => {
        websiteState.userOnboarding.allowNavigation.set(true)
      },
    },
    {
      selector: '#canvas',
      content: <ThreeDViewContent />,
      stepInteraction: true,
    },
    {
      selector: '#color_button',
      content: 'Use this button to change the color of your design',
    },
    {
      selector: '#measure_button',
      content: 'Use this button to toggle the measurements on and off',
    },
    {
      selector: '#height_button',
      content: 'Use this button to edit how high your object must be',
    },
    {
      selector: '#price_display',
      content:
        "In 3D mode you can also view the price of the object you've currently created. This updates dynamically",
    },
    {
      selector: '#ar_button',
      content:
        "This is the AR button. If you're on a supported device (iOS). This will show your object in the real world. You should try it after the tour",
    },

    {
      selector: '#bookmark_button',
      content: 'You can click here to save a design you made',
    },
    {
      selector: '#add_to_cart_button',
      content: "Click add to cart when you're happy with your design",
    },
    {
      selector: '#bottom_drawer',
      content:
        'You can scroll down from this areas if you want to look at some inspirations',
      action: () => {
        websiteState.threeDActive.set(false)
      },
    },

    {
      selector: '#magic_button',
      content:
        'If you cannot decide what to create you can click the magic button for a generated design',
    },
    {
      selector: '#help_button',
      content: <HelpButtonContent setIsOpen={setIsOpen} />,
    },
  ]
}
