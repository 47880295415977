import React from 'react'

export default function ResetIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 29 29'
    >
      <path
        fill='currentColor'
        d='M.562.818l4.107 4.108C2.135 7.453.562 10.939.562 14.792c0 7.705 6.268 13.974 13.973 13.974 7.705 0 13.974-6.269 13.974-13.974S22.24.818 14.535.818v2.795c6.164 0 11.18 5.015 11.18 11.18 0 6.163-5.016 11.178-11.18 11.178S3.356 20.956 3.356 14.792A11.13 11.13 0 016.64 6.896l3.703 3.704V.818H.562z'
      ></path>
    </svg>
  )
}
